export class CompanySettings {
    public Name: string;
    public Locale: string;
    public FileUrl: string;
    public Logo: string;
    public Timezone: string;
    constructor(
        params: {
            Name?: string,
            Locale?: string,
            FileUrl?: string,
            Logo?: string,
            Timezone?: string
        }
    ) {
        this.Name = params['Name'] || '';
        this.Locale = 'nl';
        this.FileUrl = params['FileUrl'] || '';
        this.Logo = params['Logo'] || '';
        this.Timezone = params['Timezone'] || '';
    }
}
