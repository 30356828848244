import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import {
  DynamicFormComponent,
  FieldBase,
  TextboxField,
  TextareaField,
  DateTimeField,
  Select2boxField
} from '@app/core/dynamic-form';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FieldValidationService } from '@app/core/dynamic-form/field-validation.service';
import { Image, PlainGalleryConfig, PlainGalleryStrategy, LineLayout } from '@ks89/angular-modal-gallery';
import * as moment from 'moment';

@Component({
  selector: 'app-dialog-repair-route',
  templateUrl: './dialog-repair-route.component.html'
})
export class DialogRepairRouteComponent implements OnInit, AfterViewInit {
  @ViewChild('df') private df: DynamicFormComponent;
  @Input() partOptions: any[];
  // @Input() partId: any;
  @Input() serviceItem: any;

  public form: FormGroup;
  public fields: FieldBase<any>[] = [];

  private partField: Select2boxField;
  private mainTranslateKey: string;

  // Gallery variables
  slideConfig = {
    infinite: false,
    sidePreviews: { show: false, size: { width: '100px', height: 'auto' } }
  };
  plainGalleryConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.ROW,
    layout: new LineLayout({ width: 'auto', height: '80px' }, { length: 2, wrap: true }, 'flex-start')
  };
  images: Image[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public fieldValidationSvc: FieldValidationService,
    public cdRef: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.mainTranslateKey = 'dialogRouteRepair';
  }

  ngOnInit() {
    this.buildFields();
    if (this.serviceItem.ComponentPartId) {
      this.partField.options = this.partOptions;
    }
  }

  ngAfterViewInit() {
    if (this.serviceItem.Photos.length) {
      this.createServiceImages();
    }

    this.form = this.df.form;
    this.form.patchValue(this.serviceItem);
    this.cdRef.detectChanges();
  }

  private buildFields(): void {
    this.fields = [
      new TextboxField({
        key: 'ComponentName',
        label: this.translate.instant(
          `${this.mainTranslateKey}.labelElementName`
        ),
        disable: true
      }),
      new TextboxField({
        key: 'ComponentPartName',
        label: this.translate.instant(
          `${this.mainTranslateKey}.labelElementPartName`
        ),
        disable: true,
        hidden: !!!this.serviceItem.ComponentPartId
      }),
      new TextareaField({
        key: 'Description',
        label: this.translate.instant(
          `${this.mainTranslateKey}.labelDescription`
        ),
        disable: true
      })
    ];
    if (this.serviceItem.ComponentPartId) {
      this.fields.push(
        (this.partField = new Select2boxField({
          key: 'ComponentPartCategoryId',
          subtitle: 'New part',
          label: this.translate.instant(
            `${this.mainTranslateKey}.labelRouteElementPartCategory`
          ),
          keyField: 'Id',
          valField: 'Name'
        }))
      );
      this.fields.push(
        new DateTimeField({
          key: 'DateAdded',
          label: this.translate.instant(
            `${this.mainTranslateKey}.labelDateAdded`
          ),
          type: 'date',
          pickerType: 'calendar',
          validators: [this.fieldValidationSvc.isNotFuture()],
          max: moment().format('YYYY-MM-DD'),
          errorMessages: {
            notFuture: 'Date Added cannot be in the future'
          }
        })
      );
    }
  }

  saveForm(): void {
    if (this.form.invalid) {
      this.df.updateErrors(true);
      return;
    }
    const values = this.form.value;
    this.activeModal.close(values);
  }

  private createServiceImages() {
    let id = -1;
    this.images = [];
    this.serviceItem.Photos.forEach(photo => {
      id++;
      this.images.push(
        new Image(id, {
          img: `${this.serviceItem.FileUrl}${photo.Name}`
        })
      );
    });
  }
}
