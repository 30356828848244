import { FieldBase } from './field-base';

export class RadioboxField extends FieldBase<string> {
    controlType = 'radiobox';
    options: SelectOptionsObj[];
    keyField: string;
    valField: string;
    hideWhileNoOptions: boolean;

    constructor(options: {} = {}) {
        super(options);
        this.options = options['options'] || [];
        this.keyField = options['keyField'] || 'key';
        this.valField = options['valField'] || 'value';
        this.hideWhileNoOptions = options['hideWhileNoOptions'] || false;
    }
}
