import { Component, OnInit } from '@angular/core';
import { AppComponent } from '@app/app.component';
import { ComponentTitle } from '@app/shared';
import { AppService } from '@app/core';

@Component({
  selector: 'app-token-fail',
  templateUrl: './token-fail.component.html',
  styleUrls: ['./token-fail.component.scss']
})
export class TokenFailComponent extends ComponentTitle implements OnInit {

  constructor(protected appSvc: AppService) {
    super(appSvc);
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
