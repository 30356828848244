
import { Injectable } from '@angular/core';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { DialogRoutetypeComponent } from './dialog-routetype/dialog-routetype.component';
import { DialogReportComponent } from './dialog-report/dialog-report.component';
import { DialogRouteInspectionComponent } from './dialog-route-inspection/dialog-route-inspection.component';

import { RoutePartCategory, ComponentType, CyComponent } from '@app/core/model';
import { CFG } from '../config';
import { RouteType } from '@app/core/model/route-type.model';
import { DialogFormComponent } from '@app/core/dialog/dialog-form/dialog-form.component';
import { FieldBase } from '../dynamic-form/field';
import { DialogRepairRouteComponent } from '@app/core/dialog/dialog-repair-route/dialog-repair-route.component';
import { DialogReportShowComponent } from '@app/core/dialog/dialog-report-show/dialog-report-show.component';
import { DialogComponentComponent } from '@app/core/dialog/dialog-component/dialog-component.component';
import { DialogRouteInspectionFinalizeComponent } from './dialog-route-inspection-finalize/dialog-route-inspection-finalize.component';
import { DialogGeneralComponent } from './dialog-general/dialog-general.component';

@Injectable()
export class DialogService {

    constructor(private modalSvc: NgbModal) { }

    confirm(data: { title?: string, content?: string } = {},
        options: NgbModalOptions = {}): Promise<any> {

        // Merge options.
        options = Object.assign(<NgbModalOptions>{
            backdrop: 'static',
            keyboard: false
        }, options);

        // Merge data.
        data = Object.assign(<{ title: string, content: string }>{
            title: CFG.msg.M001,
            content: CFG.msg.M002
        }, data);

        // Open the dialog.
        const modalRef = this.modalSvc.open(DialogConfirmComponent, options);

        // Set data into the modal.
        Object.assign(modalRef.componentInstance, data);

        // Return the response promise.
        return modalRef.result;
    }

    general(data: { title?: string, content?: string, actions?: any[], btnCancel?: boolean } = {},
        options: NgbModalOptions = {}): Promise<any> {

        // Merge options.
        options = Object.assign(<NgbModalOptions>{
            backdrop: 'static',
            keyboard: false,
            size: 'lg'
        }, options);

        // Open the dialog.
        const modalRef = this.modalSvc.open(DialogGeneralComponent, options);

        // Set data into the modal.
        Object.assign(modalRef.componentInstance, data);

        // Return the response promise.
        return modalRef.result;
    }


    component(data: {
        cyComponent: CyComponent
        strHelper: any,
        partOptions: RoutePartCategory[],
        compTypes: ComponentType[]
    }): Promise<any> {
        // Merge options.
        const options = <NgbModalOptions>{
            size: 'lg'
        };

        // Open the dialog.
        const modalRef = this.modalSvc.open(DialogComponentComponent, options);

        // Set data into the modal.
        Object.assign(modalRef.componentInstance, data);

        // Return the response promise.
        return modalRef.result;
    }

    routeType(data: { title?: string, routeTypes?: RouteType[] }): Promise<any> {
        // Open the dialog.
        const modalRef = this.modalSvc.open(DialogRoutetypeComponent);

        // Set data into the modal.
        Object.assign(modalRef.componentInstance, data);

        // Return the response promise.
        return modalRef.result;
    }

    report(data: { elements: any[], users: any[], settings: Settings, inspectionAttributes: any, options: any }): Promise<any> {
        // Open the dialog.
        const modalRef = this.modalSvc.open(DialogReportComponent);

        // Set data into the modal.
        Object.assign(modalRef.componentInstance, data);

        // Return the response promise.
        return modalRef.result;
    }

    reportShow(data: { reports: any, routes: any[], nmReports: number, inspectionTypes: any[]}) {
         // Open the dialog.
         const modalRef = this.modalSvc.open(DialogReportShowComponent);

         // Set data into the modal.
         Object.assign(modalRef.componentInstance, data);

         // Return the response promise.
         return modalRef.result;
    }

    routeInspection(data: { model: { RouteId: number, InspectionTypeId: number }, routes: any[], inspectionTypes: any[] }) {
        // Open the dialog.
        const modalRef = this.modalSvc.open(DialogRouteInspectionComponent);

        // Set data into the modal.
        Object.assign(modalRef.componentInstance, data);

        // Return the response promise.
        return modalRef.result;
    }

    routeRepair(data: { partOptions: any, serviceItem: any }): Promise<any> {

        // Open the dialog.
        const modalRef = this.modalSvc.open(DialogRepairRouteComponent);

        // Set data into the modal.
        Object.assign(modalRef.componentInstance, data);

        // Return the response promise.
        return modalRef.result;
    }

    form(data: { title?: string, fields: FieldBase<any>[], values: any }) {
        const modalRef = this.modalSvc.open(DialogFormComponent);

        // Set data into the modal.
        Object.assign(modalRef.componentInstance, data);

        // Return the response promise.
        return modalRef.result;
    }

    routeInspectionFinalize(data: { courses: any[], inspectionTypes: any[], exists: any }): Promise<any> {
        const options = <NgbModalOptions>{
            size: 'lg'
        };

        const modalRef = this.modalSvc.open(DialogRouteInspectionFinalizeComponent, options);


        // Set data into the modal.
        Object.assign(modalRef.componentInstance, data);

        // Return the response promise.
        return modalRef.result;
    }
}
