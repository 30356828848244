export class UserProfile {
    FileUrl: string;
    Image: string;
    Email: string;
    FirstName: string;
    LastName: string;
    PasswordOld: string;
    Password: string;
    PasswordRepeat: string;

    constructor(
        params: {
            FileUrl?: string,
            Image?: string,
            Email?: string,
            FirstName?: string,
            LastName?: string,
            PasswordOld?: string,
            Password?: string,
            PasswordRepeat?: string
        }
    ) {
        this.FileUrl = params['FileUrl'] || '';
        this.Image = params['Image'] || '';
        this.Email = params['Email'] || '';
        this.FirstName = params['FirstName'] || '';
        this.LastName = params['LastName'] || '';
        this.PasswordOld = params['PasswordOld'] || '';
        this.Password = params['Password'] || '';
        this.PasswordRepeat = params['PasswordRepeat'] || '';
    }
}
