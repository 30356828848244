import { FieldBase, TextboxField, DynamicFormComponent, RecaptchaField } from '@app/core/dynamic-form';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AuthService, HttpErrorHandlingService, ToastService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { confirmPassword } from '@app/core/dynamic-form/validator/validator-factory';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, AfterViewInit {
  @ViewChild('df') private df: DynamicFormComponent;

  // General Variables
  token = '';
  mainTranslateKey: string;

  // Form variables
  private form: FormGroup;
  fields: FieldBase<any>[] = [];

  constructor(
    private authSvc: AuthService,
    private spinnerSvc: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localize: LocalizeRouterService,
    private translate: TranslateService,
    private httpErrorHandlingSvc: HttpErrorHandlingService,
    private toastSrv: ToastService
  ) {
    this.mainTranslateKey = 'confirmationPage';
  }

  ngOnInit() {
    // Generate fields for form
    this.buildFields();

    this.spinnerSvc.show();
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    this.authSvc.checkRegisterToken(this.token)
      .subscribe((data) => {
        this.spinnerSvc.hide();
      },
      (err) => {
        this.spinnerSvc.hide();
        this.router.navigate([this.localize.translateRoute('/not-found/register')]);
      });

  }

  ngAfterViewInit() {
    this.form = this.df.form;

    this.form.get('Password').valueChanges.subscribe(val => {
      this.form.get('PasswordRepeat').updateValueAndValidity();
    });

  }

  buildFields(): void {
    this.fields = [
        new TextboxField({
            type: 'password',
            key: 'Password',
            label: this.translate.instant(`${this.mainTranslateKey}.labelPassword`),
            validators: [
              Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/),
              Validators.minLength(8)
            ],
            appendBox: true,
            appendIcon: 'info',
            // tslint:disable-next-line:max-line-length
            tooltipText: 'The password must contain at least: one lower case character, one upper case character, one number and one special characters (!@#$%^&)',
            required: true,
        }),

        new TextboxField({
            type: 'password',
            key: 'PasswordRepeat',
            label: this.translate.instant(`${this.mainTranslateKey}.labelPasswordRepeat`),
            required: true,
            validators: [
              this.confirmPasswordValidation(),
            ],
            errorMessages: {
              'confirmPassword': 'The confirm password must be equal with password'
            },
            updateOn: 'blur'
        }),
        new RecaptchaField({
          key: 'Recaptcha',
          required: true,
        })
    ];
  }

  prepareModel(): CredentialReset {
    const values = this.form.value;
    values.Token = this.token;

    return values;
  }

  onSubmit(): void {
    if ( !this.form.valid || this.form.disabled) {
      return;
    }

    const model = this.prepareModel();
    this.form.disable();

    this.authSvc.register(model).subscribe(
      (data) => this.onSuccess(data),
      (err) => this.onError(err)
    );
  }

  private onSuccess(data, msgCode?: string): void {
    this.form.markAsPristine();
    this.toastSrv.success(this.translate.instant(`${this.mainTranslateKey}.successUpdate`));
    this.router.navigate([this.localize.translateRoute('/sign-in')]);
  }

  private onError(err: HttpErrorResponse): void {
      if (err && err.status !== 401) {
          // On error show some error message.
          this.httpErrorHandlingSvc.error(err);

          // Re-enable form.
          this.form.enable();
      }
  }

  confirmPasswordValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      // tslint:disable-next-line:no-shadowed-variable
      const confirmPassword = control.value;
      let password = '';
      if (this.form) {
        password = this.form.get('Password').value;
      }
      if (password === confirmPassword) {
        return null;
      } else {
        return { 'confirmPassword': { value: confirmPassword } };
      }
    };
  }

}
