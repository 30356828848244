export class ParkUpdate {
    public Id: number;
    public Name: string;
    public Active: Number;
    public FileUrl: string;
    public Image: string;
    constructor(
        params: {
            Id?: number,
            Name?: string,
            Park?: Number,
            FileUrl?: string,
            Image?: string,
        }
    ) {
        this.Id = params['Id'] || 0;
        this.Name = params['Name'] || '';
        this.FileUrl = params['FileUrl'] || '';
        this.Image = params['Image'] || '';
        this.Active = params['Active'] ? 1 : 0;
    }
}
