import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TextboxField } from '../field';

@Component({
  selector: 'app-dynamic-textbox',
  templateUrl: './dynamic-textbox.component.html',
})
export class DynamicTextboxComponent {
  @Input() field: TextboxField;
  @Input() form: FormGroup;
}
