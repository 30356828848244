import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { RouteInspectionType } from '../model';

export const RouteInspectionTypeService = new InjectionToken<BaseFeatureModuleService<RouteInspectionType>>('RouteInspectionType');

export function routeInspectionTypeSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<RouteInspectionType> {
    return new BaseFeatureModuleService<RouteInspectionType>(
        apiSvc, 'route/inspection-type',
    );
}
