import { InjectionToken } from '@angular/core';
import { ApiService } from '../api.service';
import { BaseRouteInspectionService } from './base-route-inspection-service';



export const RouteInspectionService = new InjectionToken<BaseRouteInspectionService>('RouteInspectionService');

export function routeInspectionSvcFactory(apiSvc: ApiService): BaseRouteInspectionService {
    return new BaseRouteInspectionService(
        apiSvc, 'route/inspection',
        {
            getItems: { expand: 'User,InspectionType,Route,Park,Services.CompletedByUser,Services.AssignedTo,Services.Reporter' },
        }
    );
}
