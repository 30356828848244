import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldBase } from '../field';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '@app/core/service';

@Component({
    selector: 'app-df-field',
    templateUrl: './dynamic-form-field.component.html',
    styleUrls: ['./dynamic-form-field.component.scss'],
})
export class DynamicFormFieldComponent {
    @Input() field: FieldBase<any>;
    @Input() form: FormGroup;
    constructor(
        private translate: TranslateService,
        public utilSvc: UtilService) {
    }

    colorboxTextColor = '#fff';

    colorPickerChanged() {
        this.form.get(this.field.key).setValue(this.field.value);
        this.form.get(this.field.key).markAsDirty();
        if (this.isLightColor(this.field.value)) {
            this.colorboxTextColor = '#000';
        } else {
            this.colorboxTextColor = '#fff';
        }
    }

    isLightColor(color) {
        color = color.replace('#', '');
        const rgb = parseInt(color, 16);

        // tslint:disable-next-line:no-bitwise
        return (0.2126 * ((rgb >> 16) & 255) + 0.7152 * ((rgb >> 8) & 255) + 0.0722 * (rgb & 255)) >= 128;
    }
}
