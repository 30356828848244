import { CyElement } from './cyelement.model';

export class  CyNode {
    public group = 'nodes';
    public data: {
                    Name: string,
                    elements: any[],
                    type: number;
                };
    public  position: { x: number, y: number };

    constructor(id: string, name: string, type: number) {
        this.data = { Name: name, elements: [], type: type } ;
    }

    updateData(data: any) {
        this.data.Name = data.name;
    }

    setPosition(x: number, y: number) {
        this.position = {x: x, y: y};
    }
}
