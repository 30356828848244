import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { TokenService } from '../auth/token.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CFG } from '../config';

declare function unescape(s: string): string;
@Injectable()
export class UtilService {
  constructor(
    private tokenSvc: TokenService,
    public translate: TranslateService
  ) {}

  private baseHttpHeaders: { [header: string]: string } = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };

  static pathId(path: string, id: number, suffix = ''): string {
    return id ? path + '/' + id + suffix : path;
  }

  static pathIdDelete(path: string, id: number): string {
    return `${path}/${id}/delete`;
  }

  static equals(v1: any, v2: any): boolean {
    return JSON.stringify(v1) === JSON.stringify(v2);
  }

  static format(value: string, ...params: any[]): string {
    return value.replace(
      /{(\d+)}/g,
      (match, number) =>
        (params[number] !== 'undefined' && params[number]) || match
    );
  }

  static removeSpaces(text) {
    const str = text;
    return str.replace(/\s/g, '');
  }

  static isDeprecatedDate(date) {
    return moment(date).isBefore(moment(), 'day');
  }

  static dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  static isLightColor(color) {
    color = color.replace('#', '');
    const rgb = parseInt(color, 16);

    // tslint:disable-next-line:no-bitwise
    return (
      // tslint:disable-next-line:no-bitwise
      0.2126 * ((rgb >> 16) & 255) +
        // tslint:disable-next-line:no-bitwise
        0.7152 * ((rgb >> 8) & 255) +
        // tslint:disable-next-line:no-bitwise
        0.0722 * (rgb & 255) >=
      128
    );
  }

  static getFirstLettersAsciiSum(value: string): number {
    // tslint:disable-next-line:curly
    if (!value) return 0;

    let words = value.split(' ');
    words = words.filter(w => w !== '');
    if (words.length === 1) {
      return words[0].charCodeAt(0);
    }
    return words[0].charCodeAt(0) + words[1].charCodeAt(0);
  }

  static getColorForPercentage(pct) {
    const percentColors = [
      { pct: 0.0, color: { r: 0x00, g: 0xff, b: 0 } },
      { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
      { pct: 1.0, color: { r: 0xff, g: 0x00, b: 0 } }
    ];
    let i;
    for (i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    const lower = percentColors[i - 1];
    const upper = percentColors[i];
    const range = upper.pct - lower.pct;
    const rangePct = (pct - lower.pct) / range;
    const pctLower = 1 - rangePct;
    const pctUpper = rangePct;
    const color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  }

  static getUtcTime(date: string, timeZone: string, timeformat: string) {
    const dateTimeString = moment(date).format(CFG.serverTimeFormats.dateTime);
    return moment.tz(dateTimeString, timeZone).utc().format(timeformat);
  }

  static getTimeFromUtc(date: string, timeZone: string, timeformat: string = '') {
    const momentDate = moment.utc(`${date}+00:00`).tz(timeZone);
    if(timeformat != '') {
      return momentDate.format(timeformat);
    }
    return momentDate
  }

  buildUrl(path: string, lang = 'en'): string {
    lang = this.translate.currentLang || lang;
    return `${environment.apiUrl}${lang}/${path}`;
  }

  getFirstLetters(value: string): string {
    // tslint:disable-next-line:curly
    if (!value) return '';

    let words = value.split(' ');
    words = words.filter(w => w !== '');
    if (words.length === 1) {
      return `${words[0].charAt(0).toUpperCase()}`;
    }
    return `${words[0].charAt(0).toUpperCase()}${words[1]
      .charAt(0)
      .toUpperCase()}`;
  }

  getUserColor(name: string): string {
    const firstLettersSum = UtilService.getFirstLettersAsciiSum(name);
    return CFG.colors[+firstLettersSum % CFG.colors.length];
  }

  getObjectAttr(path, obj, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  }

  buildHttpRequestOptions(
    params: { [param: string]: string } = {}
  ): {
    headers: { [header: string]: string };
    params: { [param: string]: string };
  } {
    const forFile = params['forFile'] || false;
    return {
      headers: this.buildHttpHeaders(),
      params: params
    };
  }

  buildHttpHeaders(forFile = false): { [header: string]: string } {
    const httpHeadersObj = forFile
      ? { Accept: 'application/json' }
      : this.baseHttpHeaders;

    // Set `token` parameter
    const token = this.tokenSvc.token;
    if (token) {
      httpHeadersObj['Authorization'] = `Bearer ${token}`;
    }

    return httpHeadersObj;
  }

  buildHttpHeadersForFile() {
    const httpHeaderObj = {
      // 'Content-Type': 'multipart/form-data',
      Accept: 'application/json'
    };
    // Set `token` parameter
    const token = this.tokenSvc.token;
    if (token) {
      httpHeaderObj['Authorization'] = `Bearer ${token}`;
    }

    return httpHeaderObj;
  }
}
