import { InjectionToken } from '@angular/core';

import { BaseDailyReportService } from './base-daily-report-service';
import { ApiService } from './api.service';

import { ComplaintReport } from '../model';
import { BaseFeatureModuleService } from '@app/core/service/base-feature-module-service';

export const ComplaintReportService = new InjectionToken<BaseFeatureModuleService<ComplaintReport>>('ComplaintReport');

export function complaintReportSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<ComplaintReport> {
    return new BaseFeatureModuleService<ComplaintReport>(apiSvc, 'report/complaint', {
        getItems: { expand: 'DealtByUser,Park,ModifiedByUser' },
        getItem: { expand: 'DealtByUser' }
    });
}
