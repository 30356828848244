import { FormControl, Validators } from '@angular/forms';
import { CFG } from '../../config/app-config.constants';

export function emailValidator(control: FormControl): { [key: string]: boolean } {
    // Validate when not empty
    return control.value && Validators.email(control) || null;
}

export function urlValidator(control: FormControl): { [key: string]: boolean } {
    // Validate when not empty
    return control.value && !CFG.regex.url.test(control.value) && { url: true } || null;
}

export function  confirmPassword(control: FormControl): { [key: string]: boolean } {
      const confPassword = control.value;
      let password = '';
      if (this.form) {
        password = this.form.get('Password').value;
      }
      if (password === confPassword) {
        return null;
      } else {
        return { confirmPassword: true };
      }
}
