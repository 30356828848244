import * as moment from 'moment';
export class Report {
    ComponentId: string;
    ComponentPartId: number;
    ImportanceLevel: number;
    Description: string;
    DueDate: string;
    Hash: string;
    AssignedId: number;
    Element: string;

    constructor(
        params: {
            ComponentId?: string,
            ComponentPartId?: number,
            InportanceLevel?: number,
            Description?: string,
            DueDate?: string,
            Hash?: string,
            AssignedId?: number
            Element?: string;
        }
    ) {
        this.ComponentId = params['ComponentId'] || '';
        this.ComponentPartId = +params['ComponentPartId'] || null;
        this.ImportanceLevel = +params['ImportanceLevel'] || 0;
        this.Description = params['Description'] || '';
        this.DueDate = params['DueDate'] ? moment(params['DueDate']).format('YYYY-MM-DD') : '';
        this.Hash = params['Hash'] || '';
        this.AssignedId = params['AssignedId'] || null;
        this.Element = params['Element'] || '';
    }
}
