import { Route } from '@app/core/model';

export class Park {
    public Id: number;
    public Name: string;
    public Active: Number;
    public FileUrl: string;
    public Image: string;
    public Data: string;
    public Routes: Route[];
    public RoutesCnt: number;
    public RouteComponents: any[];
    constructor(
        params: {
            Id?: number,
            Name?: string,
            Park?: Number,
            FileUrl?: string,
            Image?: string,
            Data?: string,
            Routes?: Route[],
            RoutesCnt?: number,
            RouteComponents?: any[]
        }
    ) {
        this.Id = params['Id'] || 0;
        this.Name = params['Name'] || '';
        this.FileUrl = params['FileUrl'] || '';
        this.Image = params['Image'] || '';
        this.Data = params['Data'] || '';
        this.Routes = params['Routes'] || [];
        this.RoutesCnt = params['RoutesCnt'] || 0;
        this.RouteComponents = params['RouteComponents'] || [];
        this.Active = params['Active'] ? 1 : 0;
    }
}
