import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';

import { AppService, DatatablePageService, UtilService } from '@app/core/service';
import { ComponentTitle } from '@app/shared/class';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class SharedDatatableComponent extends ComponentTitle
  implements OnInit, OnChanges {
  @ViewChild('tableWrapper') tableWrapper;
  @ViewChild(DatatableComponent) datatable: DatatableComponent;
  @ViewChild('linkTpl')
  linkTpl: TemplateRef<any>;
  @ViewChild('dateTpl')
  dateTpl: TemplateRef<any>;
  @ViewChild('dateTimeTpl')
  dateTimeTpl: TemplateRef<any>;
  @ViewChild('descriptionTpl')
  descriptionTpl: TemplateRef<any>;
  @ViewChild('detailMobile')
  detailMobile: TemplateRef<any>;
  @ViewChild('actionTpl')
  actionTpl: TemplateRef<any>;

  @Input()
  id: string;
  @Input()
  icon: string;
  @Input()
  rows: {}[];
  @Input()
  columns: any[];
  @Input()
  hasTitle = true;
  @Input()
  columnMode = 'force';
  @Input()
  rowClass: any;

  @Input()
  addBtn = true;
  @Input()
  addBtnTextTranslateKey = 'datatableactions.add';
  @Input()
  hasUpdatePermission = false;
// UI Switch
  @Input()
  uiSwitchLabel = '';
  @Output()
  uiSwitchChange = new EventEmitter();

  // Display
  @Input()
  isInnerTable = false;
  @Input()
  loading = true;
  @Input()
  showTable = false;
  @Input()
  hasCard = true;
  @Input()
  isNotResponsive = false;
  @Input()
  responsiveColumnCondition: string;
  @Input()
  useSpinner = false;
  @Input()
  responsiveWidth = 800;
  @Input()
  defaultSortProp = '';
  @Input()
  defaultSortDir = 'asc';

  // Pagination
  @Input()
  offset = 0;
  @Input()
  count = 0;
  @Input()
  externalPaging = false;
  @Input()
  limit = 10;
  @Input()
  filters = false;

  // Details
  @Input()
  showRowDetail = false;
  @Input()
  innerDetail: TemplateRef<any>;

  @Output()
  page = new EventEmitter();
  @Output()
  sort = new EventEmitter();
  @Output()
  toggleFilters = new EventEmitter();

  // Sort
  sortProp: string;
  sortDir: string;

  expanded: any = {};
  showFilters = true;
  mobileColumns: any[];
  responsiveColumns: any[];
  appearedColumns: any[];

  tableWidth: number;
  private currentComponentWidth;
  responsiveMode = false;

  constructor(
    private route: ActivatedRoute,
    protected appSvc: AppService,
    private dtPageSvc: DatatablePageService,
    private cdRef: ChangeDetectorRef,
    public spinnerSvc: NgxSpinnerService,
    public utilSvc: UtilService
  ) {
    super(appSvc);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.useSpinner) {
      this.spinnerSvc.show();
    }

    this.offset = this.route.snapshot.queryParamMap.get('page')
      ? +this.route.snapshot.queryParamMap.get('page') - 1
      : 0;

    this.sortDir = this.route.snapshot.queryParamMap.get('dir')
      ? this.route.snapshot.queryParamMap.get('dir')
      : this.defaultSortDir;

    this.sortProp = this.route.snapshot.queryParamMap.get('prop')
      ? this.route.snapshot.queryParamMap.get('prop')
      : this.defaultSortProp;


    this.tableWidth = this.tableWrapper.nativeElement.clientWidth;
    this.mobileColumns = _.filter(
      this.columns,
      column => column.cellClass && column.cellClass.includes('mobile')
    );

    // tslint:disable-next-line:max-line-length
    this.responsiveColumns = _.map(
      _.filter(
        this.columns,
        column =>
          (column.cellClass && !column.cellClass.includes('mobile')) ||
          !column.cellClass
      ),
      item => {
        if (item.name && item.maxWidth && !item.useMaxWidth) {
          delete item['maxWidth'];
        }
        return item;
      }
    );
    this.getColumnsBySize();
    this.initPageSvc();
    if (this.id) {
      this.dtPageSvc.page = this.offset + 1;
    }
  }

  ngOnChanges(changes) {
    if (changes.rows) {
      this.rows = changes.rows.currentValue;
      setTimeout(() => {
        this.showTalbeFn();
      }, 1000);
    }
  }

  onPaginated(params) {
    if (!this.id) {
      return;
    }

    this.dtPageSvc.tableId = this.id;
    this.dtPageSvc.page = params.offset + 1;
    this.page.emit(params);
  }

  onSort(params): void {
    if (!this.id) {
      return;
    }

    this.dtPageSvc.sort = params.sorts;
    this.dtPageSvc.tableId = this.id;
    this.sort.emit(params);
  }

  onUiSwitchChange(params): void {
    this.uiSwitchChange.emit(params);
  }

  fixBugColumnResize() {
    if (this.datatable && this.datatable.recalculate) {
      this.datatable.recalculate();
      this.datatable.recalculateColumns(this.columns);
      window.dispatchEvent(new Event('resize'));
    }
  }

  toggleExpandRow(row) {
    this.datatable.rowDetail.toggleExpandRow(row);
  }

  recalculate() {
    if (
      this.datatable &&
      this.datatable.recalculate &&
      this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth
    ) {
      this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
      this.datatable.recalculate();
      this.cdRef.markForCheck();
    }
  }

  private initPageSvc() {
    if (!this.id) {
      return;
    }
    if (this.id && this.id !== this.dtPageSvc.tableId) {
      this.dtPageSvc.page = 1;
      this.dtPageSvc.sort = { prop: '', sort: '' };
    }
  }

  private showTalbeFn() {
    if (this.useSpinner) {
      this.spinnerSvc.hide();
    }
    this.showTable = true;
  }

  getMobileToggle() {
    return {
      width: 35,
      resizeable: false,
      sortable: false,
      draggable: false,
      canAutoResize: false,
      cellTemplate: this.detailMobile
    };
  }

  @HostListener('window:resize', ['$event'])
  getColumnsBySize(event?) {
    this.tableWidth = this.tableWrapper.nativeElement.clientWidth;
    this.responsiveMode = this.tableWidth < this.responsiveWidth;
    if (this.isNotResponsive) {
      this.appearedColumns = Object.assign([], this.columns);
      return;
    }

    if (this.responsiveMode) {
      this.appearedColumns = Object.assign([], this.responsiveColumns);
    } else {
      this.appearedColumns = Object.assign([], this.columns);
    }

    if (this.responsiveMode || this.showRowDetail) {
      this.appearedColumns.unshift(this.getMobileToggle());
    }
  }

  onToggleFilters() {
    this.showFilters = !this.showFilters;
    this.toggleFilters.emit();
  }
}
