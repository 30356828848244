import { EquipmentServiceInterface } from './belt/equpment-service.interface';

export class ServiceBelt implements EquipmentServiceInterface {
    public type: 'service-belt';
    public Id: number;
    public BeltId: number;
    public AssignedId: number;
    public CheckTypeId: number;
    public Description: string;
    public DueDate: string;
    public Category: string;
    public CheckType: string;
    public CreateDate: string;
    public Reporter: { FileUrl: string, Name: string, Image: string };
    public DateCompleted: string;
    public Belt: any;
    public Parts: any[];
    constructor(
        params: {
            Id?: number,
            BeltId?: number,
            AssignedId?: number,
            CheckTypeId?: number,
            Description?: string,
            DueDate?: string,
            Category?: string,
            CheckType?: string,
            CreateDate?: string,
            Reporter?: { FileUrl: string, Name: string, Image: string },
            DateCompleted?: string,
            Parts?: any[],
            Belt?: any,
        }
    ) {
        this.Id = params.Id || 0;
        this.BeltId = params.BeltId || 0;
        this.AssignedId = params.AssignedId || null;
        this.CheckTypeId = params.CheckTypeId || 0;
        this.Description = params.Description || '';
        this.DueDate = params.DueDate || '';
        this.Category = params.Category || '';
        this.CheckType = params.CheckType || '';
        this.CreateDate = params.CreateDate || '';
        this.Reporter = params.Reporter || null;
        this.DateCompleted = params.DateCompleted || '';
        this.Parts = params.Parts || [];
        this.Belt = params.Belt || {};
    }
}
