export class Address {
  public PostCode: string;
  public Street: string;
  public City: string;
  public County: string;
  public CountryId: number;
  public Town: string;

  constructor(params: {
    PostCode?: string,
    Street?: string,
    City?: string,
    County?: string,
    CountryId?: number,
    Town?: string
  }) {
    this.PostCode = params.PostCode || '';
    this.Street = params.Street || '';
    this.City = params.City || '';
    this.County = params.County || '';
    this.CountryId = params.CountryId || 0;
    this.Town = params.Town || '';
  }
}
