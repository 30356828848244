import { Component, OnInit, Input, AfterViewInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { FieldBase } from './../../dynamic-form/field/field-base';
import { FormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-dialog-form',
  templateUrl: './dialog-form.component.html',
  styleUrls: ['./dialog-form.component.scss']
})
export class DialogFormComponent implements AfterViewInit {
  @ViewChild('df') private df: DynamicFormComponent;
  @Input() title: string;
  @Input() fields: FieldBase<any>[] = [];
  @Input() values: any;

  form: FormGroup;
  disableSave = true;

  constructor(
    public activeModal: NgbActiveModal,
    private cdRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngAfterViewInit() {
    this.form = this.df.form;
    if (this.document.getElementById(this.fields[0].key)) {
      this.document.getElementById(this.fields[0].key).focus();
    }

    this.disableSave = false;
    if (this.values) {
      this.form.patchValue(this.values);
    }

    this.cdRef.detectChanges();
  }

  public saveForm() {
    if (!this.form.disabled && this.form.valid) {
      const values = this.form.value;
      // Close dialog and return new/edited item and parts
    this.activeModal.close({ values:  values});
    } else {
      this.form.enable();
    }
  }

}
