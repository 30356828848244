import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {CustomCheckboxComponent} from '@app/shared/custom-checkbox/custom-checkbox.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    CustomCheckboxComponent
  ],
  exports: [
    CommonModule,
    TranslateModule,
    CustomCheckboxComponent
  ]
})
export class SharedModule {
}
