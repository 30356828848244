import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { Role } from '@app/core';

@Injectable()
export class CountryService {
    private countries: Country[];

    constructor(private apiSvc: ApiService) { }

    getItems(): Observable<Country[]> {
        return this.countries
            && of(this.countries)
            || this.apiSvc
                .get('country')
                .pipe(map((data: ApiResponseDataCollection) => this.countries = data.Data));
    }
}
