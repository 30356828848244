import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { DynamicFormComponent } from './dynamic-form.component';
import { DynamicFormFieldComponent } from './dynamic-form-field/dynamic-form-field.component';

import { FieldControlService } from './field-control.service';
import { DynamicFormGroupComponent } from './dynamic-form-group/dynamic-form-group.component';
import { DynamicFormDzFieldComponent } from './dynamic-form-dz-field/dynamic-form-dz-field.component';
import { DynamicFormDzService } from '@app/core/dynamic-form/dynamic-form-dz-field/dynamic-form-dz-service';

import { ColorPickerModule } from 'ngx-color-picker';
import { FieldValidationService } from '@app/core/dynamic-form/field-validation.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import {
    OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS,
} from 'ng-pick-datetime';
import { MOMENT_FORMATS } from '../config';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng5SliderModule } from 'ng5-slider';
import { DynamicSelectComponent } from './dynamic-select/dynamic-select.component';
import { DynamicTextboxComponent } from './dynamic-textbox/dynamic-textbox.component';
import { DynamicDateTimeComponent } from './dynamic-date-time/dynamic-date-time.component';
import { DynamicUiSwitchComponent } from './dynamic-ui-switch/dynamic-ui-switch.component';
import { DynamicColorboxComponent } from './dynamic-colorbox/dynamic-colorbox.component';
import { DynamicTextareaComponent } from './dynamic-textarea/dynamic-textarea.component';
import { DynamicSliderComponent } from './dynamic-slider/dynamic-slider.component';
import { DynamicRecaptchaComponent } from './dynamic-recaptcha/dynamic-recaptcha.component';
import { DynamicRadioboxComponent } from './dynamic-radiobox/dynamic-radiobox.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    // Change this to your upload POST address:
     maxFilesize: 5
   };


@NgModule({
    declarations: [
        DynamicFormComponent,
        // DynamicFormDzComponent,
        DynamicFormFieldComponent,
        DynamicFormGroupComponent,
        DynamicFormDzFieldComponent,
        DynamicSelectComponent,
        DynamicTextboxComponent,
        DynamicDateTimeComponent,
        DynamicUiSwitchComponent,
        DynamicColorboxComponent,
        DynamicTextareaComponent,
        DynamicSliderComponent,
        DynamicRecaptchaComponent,
        DynamicRadioboxComponent,
        // DialogConfirmComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        DropzoneModule,
        ColorPickerModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        TranslateModule,
        LocalizeRouterModule,
        NgSelectModule,
        UiSwitchModule,
        NgxCaptchaModule,
        NgbTooltipModule,
        Ng5SliderModule
    ],
    exports: [
        DynamicFormComponent,
        // DynamicFormDzComponent,
        DynamicFormFieldComponent,
    ],
    providers: [
        {
            provide: DROPZONE_CONFIG,
            useValue: DEFAULT_DROPZONE_CONFIG
        },
        {
            provide: OWL_DATE_TIME_FORMATS,
            useValue: MOMENT_FORMATS.MY_NATIVE_FORMATS
        },
        DynamicFormDzService,
        FieldValidationService
    ]
})
export class DynamicFormModule {
    // This module is can be imported by lazy-loaded modules,
    // so we need to "configure" the `FieldControlService`.
    // This ensures that the service will be app wide singleton.
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: DynamicFormModule,
            providers: [
                FieldControlService
            ]
        };
    }
}
