import { IncidentReportHandling } from './incident-report-handling.model';
import * as moment from 'moment';
import 'moment-timezone';
import { CFG } from '../config';
export class IncidentReport {
    public Id: number;
    public ParkId: number;
    public EngagedEmployee: string;
    public ActivityOrRoute: string;
    public Description: string;
    public Cause: string;
    public Circumstance: string;
    public FirstAid: any;
    public FirstAidPostOrDoctor: any;
    public Ambulance: any;
    public Hospital: any;
    public FirstName: string;
    public LastName: string;
    public Address: string;
    public Residence: string;
    public PostCode: string;
    public Email: string;
    public DayManagerId: number;
    public DateTime: any;
    public DateAdded: string;
    public DateUpdated: string;
    public ModifierUserId: number;
    public Status: number;
    public IncidentReportHandling: IncidentReportHandling;
    public ModifiedByUser: string;
    public Park: string;

    constructor(
        report: {
            Id?: number,
            ParkId?: number,
            EngagedEmployee?: string,
            ActivityOrRoute?: string,
            Description?: string,
            Cause?: string,
            Circumstance?: string,
            FirstAid?: number,
            FirstAidPostOrDoctor?: number,
            Ambulance?: number,
            Hospital?: number,
            FirstName?: string,
            LastName?: string,
            Address?: string,
            Residence?: string,
            Email?: string,
            DayManagerId?: number,
            DateTime?: any,
            DateAdded?: string,
            DateUpdated?: string,
            ModifierUserId?: number,
            Status?: number,
            IncidentReportHandling?: IncidentReportHandling,
            ModifiedByUser?: string,
            Park?: string
        }
    ) {
        this.Id = report.Id || 0;
        this.ParkId = report.ParkId || 0;
        this.EngagedEmployee = report.EngagedEmployee || '';
        this.ActivityOrRoute = report.ActivityOrRoute || '';
        this.Description = report.Description || '';
        this.Cause = report.Cause || '';
        this.Circumstance = report.Circumstance || '';
        this.FirstAid = report.FirstAid;
        this.FirstAidPostOrDoctor = report.FirstAidPostOrDoctor;
        this.Ambulance = report.Ambulance;
        this.Hospital = report.Hospital;
        this.FirstName = report.FirstName || '';
        this.LastName = report.LastName || '';
        this.Address = report.Address || '';
        this.Residence = report.Residence || '';
        this.Email = report.Email || '';
        this.DayManagerId = report.DayManagerId || 0;
        this.DateTime = report.DateTime || '';
        this.DateAdded = report.DateAdded || '';
        this.DateUpdated = report.DateUpdated || '';
        this.ModifierUserId = report.ModifierUserId || 0;
        this.Status = report.Status || 0;
        this.IncidentReportHandling = report.IncidentReportHandling || new IncidentReportHandling({});
        this.ModifiedByUser = report.ModifiedByUser || '';
        this.Park = report.Park || '';
    }

    public prepareForClient(timeZone: string) {
        this.FirstAid = this.getValueJaNeNvtBySide(this.FirstAid, false);
        this.FirstAidPostOrDoctor = this.getValueJaNeNvtBySide(this.FirstAidPostOrDoctor, false);
        this.Ambulance = this.getValueJaNeNvtBySide(this.Ambulance, false);
        this.Hospital = this.getValueJaNeNvtBySide(this.Hospital, false);
        this.DateTime = new Date(moment.utc(this.DateTime).tz(timeZone).format(CFG.serverTimeFormats.dateTime));
        // this.DateTime = new Date(moment(this.DateTime).format('YYYY-MM-DD HH:mm:ss'));
    }

    public prepareForServer(timeZone: string) {
        this.FirstAid = this.getValueJaNeNvtBySide(this.FirstAid, true);
        this.FirstAidPostOrDoctor = this.getValueJaNeNvtBySide(this.FirstAidPostOrDoctor, true);
        this.Ambulance = this.getValueJaNeNvtBySide(this.Ambulance, true);
        this.Hospital = this.getValueJaNeNvtBySide(this.Hospital, true);

        const dateTimeString = moment(this.DateTime).format(CFG.serverTimeFormats.dateTime);
        this.DateTime = moment.tz(dateTimeString, timeZone).utc().format(CFG.serverTimeFormats.dateTime);
        // this.DateTime = moment(this.DateTime).format('YYYY-MM-DD HH:mm:ss');
        this.IncidentReportHandling = null;
    }

    private getValueJaNeNvtBySide(value: any, isClient: boolean) {
        if (isClient) {
            switch (value) {
                case 'ja':
                    return 1;
                case 'nee':
                    return 0;
                default:
                    return null;
            }
        } else {
            switch (value) {
                case 1:
                    return 'ja';
                case 0:
                    return 'nee';
                default:
                    return 'nvt';
            }
        }
    }
}
