import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { UtilService } from './util.service';

import { DailyReport } from '../model';
import { BaseFeatureModuleService } from './base-feature-module-service';
@Injectable()
export class BaseDailyReportService extends BaseFeatureModuleService<DailyReport> {

    private reports: DailyReport[];

    constructor(
        private apiSvcC: ApiService,
        private pathC: string,
        private reqParamsC: { getItems?: StringStringMap, getItem?: StringStringMap } = {}
    ) {
        super(apiSvcC, pathC, reqParamsC);
    }

    getItemsWithMeta(metaInfo: any): Observable<{ list: DailyReport[], _meta: any }> {
        this.reqParamsC.getItems['page'] = metaInfo.page;
        this.reqParamsC.getItems['parkId'] = metaInfo.parkId;
        this.reqParamsC.getItems['expand'] = metaInfo.expand;
        this.reqParamsC.getItems['sort'] = metaInfo.sort;

        return this.apiSvcC
            .get(this.pathC, this.reqParamsC.getItems || {})
            .pipe(map((data: ApiResponseDataCollection) => {
                data._meta.pageNumber = data._meta.currentPage - 1;
                return { list: data.Data, _meta: data._meta };
            }));
    }

    getSettings(): Observable<any> {
        return this.apiSvcC
            .get(`${this.pathC}/settings`)
            .pipe(map((data: ApiResponse) => data));
    }

    getListSettings(): Observable<any> {
        return this.apiSvc
            .get(`${this.pathC}/settings/list`)
            .pipe(map((data: ApiResponse) => data));
    }

    getStatsByParkId(parkId: number, params): Observable<any> {
        return this.apiSvcC
            .get(`${this.pathC}/settings/${parkId}/stats`, params)
            .pipe(map((data: ApiResponse) => data));
    }

}
