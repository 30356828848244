import { FieldBase } from './field-base';
import { CFG } from '@app/core/config';
import { UtilService } from '@app/core/service';

export class Select2boxField extends FieldBase<string> {
    controlType = 'select2box';
    options: any[];
    keyField: string;
    valField: string;
    multiple: boolean;
    searchable: boolean;
    placeholder: string;
    hideWhileNoOptions: boolean;
    closeOnSelect: boolean;
    default: { value: any, label: string };
    avatar: boolean;
    avatarKey: string;
    clearable: boolean;

    constructor(options: {} = {}) {
        super(options);
        this.options = options['options'] || [];
        this.multiple = options['multiple'] || false;
        this.keyField = options['keyField'] || 'key';
        this.valField = options['valField'] || 'value';
        this.hideWhileNoOptions = options['hideWhileNoOptions'] || false;
        this.closeOnSelect = options['closeOnSelect'] === false ? false : true;
        this.placeholder = options['placeholder'] || '';
        this.default = options['default'] || null;
        this.avatar = options['avatar'] || false;
        this.searchable = this.avatar || options['searchable'] || false;
        this.avatarKey = options['avatarKey'] || 'img';
        this.clearable = options['clearable'] === false ? false : true;
        // tslint:disable-next-line:max-line-length
    }

    addOptions(options: any[]) {
        this.options = Object.assign([], options);
        if (!this.avatar) {
            return;
        }

        this.options.forEach(o => {
            const firstLettersSum = UtilService.getFirstLettersAsciiSum(o[this.valField]);
            o['color'] = CFG.colors[+firstLettersSum % CFG.colors.length];
        });
    }
}
