import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OwlDateTimeIntl } from 'ng-pick-datetime';

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {

    /** A label for the up second button (used by screen readers).  */
    upSecondLabel;

    /** A label for the down second button (used by screen readers).  */
    downSecondLabel;

    /** A label for the up minute button (used by screen readers).  */
    upMinuteLabel;

    /** A label for the down minute button (used by screen readers).  */
    downMinuteLabel;

    /** A label for the up hour button (used by screen readers).  */
    upHourLabel;

    /** A label for the down hour button (used by screen readers).  */
    downHourLabel;

    /** A label for the previous month button (used by screen readers). */
    prevMonthLabel;

    /** A label for the next month button (used by screen readers). */
    nextMonthLabel;

    /** A label for the previous year button (used by screen readers). */
    prevYearLabel;

    /** A label for the next year button (used by screen readers). */
    nextYearLabel;

    /** A label for the previous multi-year button (used by screen readers). */
    prevMultiYearLabel;

    /** A label for the next multi-year button (used by screen readers). */
    nextMultiYearLabel;

    /** A label for the 'switch to month view' button (used by screen readers). */
    switchToMonthViewLabel;

    /** A label for the 'switch to year view' button (used by screen readers). */
    switchToMultiYearViewLabel;

    /** A label for the cancel button */
    cancelBtnLabel;

    /** A label for the set button */
    setBtnLabel;

    /** A label for the range 'from' in picker info */
    rangeFromLabel;

    /** A label for the range 'to' in picker info */
    rangeToLabel;

    /** A label for the hour12 button (AM) */
    hour12AMLabel;

    /** A label for the hour12 button (PM) */
    hour12PMLabel;

    constructor(private translate: TranslateService) {
        super();
        this.upSecondLabel = this.translate.instant('owlDateTimePicker.upSecondLabel');
        this.downSecondLabel = this.translate.instant('owlDateTimePicker.downSecondLabel');
        this.upMinuteLabel = this.translate.instant('owlDateTimePicker.upMinuteLabel');
        this.downMinuteLabel = this.translate.instant('owlDateTimePicker.downMinuteLabel');
        this.upHourLabel = this.translate.instant('owlDateTimePicker.upHourLabel');
        this.downHourLabel = this.translate.instant('owlDateTimePicker.downHourLabel');
        this.prevMonthLabel = this.translate.instant('owlDateTimePicker.prevMonthLabel');
        this.nextMonthLabel = this.translate.instant('owlDateTimePicker.nextMonthLabel');
        this.prevYearLabel = this.translate.instant('owlDateTimePicker.prevYearLabel');
        this.nextYearLabel = this.translate.instant('owlDateTimePicker.nextYearLabel');
        this.prevMultiYearLabel = this.translate.instant('owlDateTimePicker.prevMultiYearLabel');
        this.nextMultiYearLabel = this.translate.instant('owlDateTimePicker.prevMultiYearLabel');
        this.switchToMonthViewLabel = this.translate.instant('owlDateTimePicker.switchToMonthViewLabel');
        this.switchToMultiYearViewLabel = this.translate.instant('owlDateTimePicker.switchToMultiYearViewLabel');
        this.cancelBtnLabel = this.translate.instant('owlDateTimePicker.cancelBtnLabel');
        this.setBtnLabel = this.translate.instant('owlDateTimePicker.setBtnLabel');
        this.rangeFromLabel = this.translate.instant('owlDateTimePicker.rangeFromLabel');
        this.rangeToLabel = this.translate.instant('owlDateTimePicker.rangeToLabel');
        this.hour12AMLabel = this.translate.instant('owlDateTimePicker.hour12AMLabel');
        this.hour12PMLabel = this.translate.instant('owlDateTimePicker.hour12PMLabel');
    }
}
