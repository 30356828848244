import { InjectionToken } from '@angular/core';
import { ApiService } from '../api.service';
import { BaseRouteInspectionService } from './base-route-inspection-service';
import { BaseFeatureModuleService } from '../base-feature-module-service';
import { RouteServiceHistory } from '@app/core/model';



export const RouteServiceHistoryService = new InjectionToken<BaseFeatureModuleService<RouteServiceHistory>>('RouteServiceHistoryService');

export function routeServiceHistorySvcFactory(apiSvc: ApiService): BaseFeatureModuleService<RouteServiceHistory> {
    return new BaseRouteInspectionService(
        apiSvc, 'route/service/history',
        {
            // tslint:disable-next-line:max-line-length
            getItems: { expand: 'Photos,ComponentName,ComponentPart,RouteComponentPartCategory,Inspection.User,Inspection.InspectionType,Inspection.Route.Park,AssignedTo,CompletedByUser' },
        }
    );
}
