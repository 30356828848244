
import {throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

import { CFG } from '../config';

import { TokenService } from './token.service';
import { UtilService } from '../service/util.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ToastService } from '../service/toast.service';

@Injectable()
export class AuthService {

    private isAuthenticatedBS$ = new BehaviorSubject<boolean>(!!this.tokenSvc.token);
    public isAuthenticated$ = this.isAuthenticatedBS$.asObservable();

    // Store the URL so we can redirect after logging in.
    redirectUrl: string;

    constructor(private httpClient: HttpClient,
                private router: Router,
                private localize: LocalizeRouterService,
                private utilSvc: UtilService,
                private tokenSvc: TokenService,
                private toastSvc: ToastService,
                ) {

        if (this.tokenSvc.token) {

            let tokenExpired: boolean;

            // Remove any potential remnants of previous auth states,
            // when the JWT token exists and cannot be parsed
            // (maybe because was compromised in the `localstorage`) or it is expired.
            if (!this.tokenSvc.parseToken()
                || (tokenExpired = this.tokenSvc.payload.exp < new Date().getTime())) {
                this.logout(tokenExpired);
            }
            // The JWT token is not expired and not compromised.
            else {

                // Set auth status to `true`.
                this.isAuthenticatedBS$.next(true);
            }
        }
    }

    /**
     * Log in with the passed credentials.
     * @param {Credentials} credentials
     * @returns {Observable<any>}
     */
    login(credentials: Credentials): Observable<any> {
        return this.httpClient.post(
            this.utilSvc.buildUrl('session?expand=Settings'),
            JSON.stringify(credentials),
            this.utilSvc.buildHttpRequestOptions()
        ).pipe(
            catchError((err: HttpErrorResponse) => observableThrowError(err)),
            map((resp: ApiResponse) => {

                try {
                    // Parse and save the JWT token to the localstorage.
                    this.tokenSvc.token = resp.data.Token;

                    // Set auth status to `true`.
                    this.isAuthenticatedBS$.next(true);
                } catch (e) {
                    return false;
                }

               return resp;
            })
        );
    }

    /**
     * Log out the user.
     * @param {boolean} isTokenExpired
     */
    logout(isTokenExpired?: boolean): void {
        // Show JWT token expired toast.
        if (isTokenExpired) {
            // Clear the current auth data.
            this.purgeAuth();

            // Redirect to login.
            this.router.navigate([this.localize.translateRoute('/sign-in')]);

            this.toastSvc.error(CFG.error.E006);
            return;
        }

        this.httpClient.post(
            this.utilSvc.buildUrl('session/logout'),
            JSON.stringify({}),
            this.utilSvc.buildHttpRequestOptions()
        ).subscribe(data => {
            this.purgeAuth();
            this.router.navigate([this.localize.translateRoute('/sign-in')]);
        });
    }

    /**
     * Chetk token validity.
     * @param {string} token
     * @returns {Observable<any>}
     */
    checkRegisterToken(token: string): Observable<any> {
        return this.httpClient.post(
            this.utilSvc.buildUrl('session/register/check'),
            { Token: token },
            this.utilSvc.buildHttpRequestOptions()
        ).pipe(
            catchError((err: HttpErrorResponse) => observableThrowError(err)),
            map((resp: ApiResponse) => {
               return resp;
            })
        );
    }

    /**
     * Chetk token validity.
     * @param {string} token
     * @returns {Observable<any>}
     */
    checkForgotPasswordToken(token: string): Observable<any> {
        return this.httpClient.post(
            this.utilSvc.buildUrl('session/reset/check'),
            { Token: token },
            this.utilSvc.buildHttpRequestOptions()
        ).pipe(
            catchError((err: HttpErrorResponse) => observableThrowError(err)),
            map((resp: ApiResponse) => {
               return resp;
            })
        );
    }

    /**
     * Chetk token validity.
     * @param {string} token
     * @returns {Observable<any>}
     */
    register(credentials: CredentialReset): Observable<any> {
        return this.httpClient.post(
            this.utilSvc.buildUrl('session/register'),
            JSON.stringify(credentials),
            this.utilSvc.buildHttpRequestOptions()
        ).pipe(
            catchError((err: HttpErrorResponse) => observableThrowError(err)),
            map((resp: ApiResponse) => {
               return resp;
            })
        );
    }

    /**
     * Chetk token validity.
     * @param {string} token
     * @returns {Observable<any>}
     */
    reset(credentials: CredentialReset): Observable<any> {
        return this.httpClient.post(
            this.utilSvc.buildUrl('session/reset'),
            JSON.stringify(credentials),
            this.utilSvc.buildHttpRequestOptions()
        ).pipe(
            catchError((err: HttpErrorResponse) => observableThrowError(err)),
            map((resp: ApiResponse) => {
               return resp;
            })
        );
    }


    /**
     * Clear the authentication data and set auth status to `false`.
     */
    purgeAuth(): void {
        // Remove the JWT token from localstorage.
        this.tokenSvc.destroyToken();

        // Set auth status to `false`.
        this.isAuthenticatedBS$.next(false);
    }

}
