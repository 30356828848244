export const MOMENT_FORMATS = Object.freeze({
    'nl': {
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD-MM-YYYY',
            LL : 'DD-MM-YYYY',
            LLL : 'DD-MM-YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm',
            l: 'MM-YYYY'
        }
    },
    'en-GB': {
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD/MM/YYYY',
            LL : 'DD/MM/YYYY',
            LLL : 'DD/MM/YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm',
            l: 'MM/YYYY'
        }
    },
    'de': {
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD.MM.YYYY',
            LL : 'DD.MM.YYYY',
            LLL : 'DD.MM.YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm',
            l: 'MM.YYYY'
        }
    },
    MY_NATIVE_FORMATS: {
        fullPickerInput: {year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric'},
        datePickerInput: {year: 'numeric', month: '2-digit', day: '2-digit'},
        timePickerInput: {hour: 'numeric', minute: 'numeric'},
        monthYearLabel: {year: 'numeric', month: 'long'},
        dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
        monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
});
