export class Role {
    Id: string;
    Name: string;
    Permissions: string[];

    constructor(
        params: {
            Id?: string,
            Name?: string,
            Permissions?: string[]
        }
    ) {
        this.Id = params['Id'] || '';
        this.Name = params['Name'] || '';
        this.Permissions = params['Permissions'] || [];
    }

    prepareForClient() {
        const tmpPerm: any = {};
        this.Permissions.forEach(perm => tmpPerm[perm] = true);
        this.Permissions = tmpPerm;
    }

    prepareForServer() {
        const tmpPerm: string[] = [];
        Object.keys(this.Permissions).forEach(key => {
            if (this.Permissions[key]) {
                tmpPerm.push(key);
            }
        });
        this.Permissions = tmpPerm;
    }
}
