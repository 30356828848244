import { NgModule } from '@angular/core';

import { SignInComponent } from './sign-in/sign-in.component';
import { DynamicFormModule } from '@app/core';
import { AuthRoutingModule } from './auth-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CommonModule } from '@angular/common';
import { ForgotPasswordResetComponent } from './forgot-password-reset/forgot-password-reset.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { TokenFailComponent } from './token-fail/token-fail.component';

@NgModule({
    declarations: [
        SignInComponent,
        ForgotPasswordComponent,
        ForgotPasswordResetComponent,
        ConfirmationComponent,
        TokenFailComponent
    ],
    imports: [
        CommonModule,
        DynamicFormModule,
        TranslateModule,
        AuthRoutingModule,
        TranslateModule,
        NgxSpinnerModule
    ],
    exports: [
        TranslateModule
    ],
})
export class AuthModule {
}
