import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Data,
    Router,
    RouterStateSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { TokenService } from './token.service';
import { AppService } from '../service/app.service';
import { SettingsService } from '../service/settings.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

/**
 * This guard will check the user authentication and authorization for the activated route.
 */
@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {

    constructor(private router: Router,
        private authSvc: AuthService,
        private tokenSvc: TokenService,
        private appSvc: AppService,
        private localize: LocalizeRouterService,
        private settingsSvc: SettingsService) { }

    /**
     * @inheritDoc
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        // Check the activated route has the `chkTokenExp` flag in the data property.
        let chkTokenExp = !!route.data.chkTokenExp;

        // When the activated route, doesn't has the `chkTokenExp` flag, then
        // we find for the flag in the child routes data property until the first match.
        // This flag is mainly used for the routes that contains editable data (eg. forms).
        if (!chkTokenExp) {

            while (route.firstChild) {
                route = route.firstChild;

                if ((chkTokenExp = !!route.data.chkTokenExp)) {
                    break;
                }
            }
        }
        return this.isAuthenticated(route.data, state.url, chkTokenExp);
    }

    /**
     * @inheritDoc
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    // canLoad(route: Route): Observable<boolean> {
    //     return this.isAuthenticated(route.data, `/${route.path}`);
    // }

    /**
     * Checks the user need authentication for the activated route.
     * @param {Data} routeData the route date object.
     * @param {string} url the activated url.
     * @param {boolean} chkTokenExp whether need to check the token expiration.
     * @returns {Observable<boolean>} whether the user is authenticated.
     */
    isAuthenticated(routeData: Data, url: string, chkTokenExp: boolean = false): Observable<boolean> {
        return this.authSvc.isAuthenticated$
            .pipe(
                take(1),
                map((isAuthenticated: boolean) => {
                    let tokenExpired: boolean;

                    // `withAuth` default value is true, which means all routes protected with this guard, which does not
                    // have a `withAuth` `Data` parameter, it can be accessed only by authenticated users.
                    const withAuth = ('withAuth' in routeData) ? routeData.withAuth : true;

                    // Restrict the access of the route, when the user is trying to reach a route which is not allowed
                    // (`withAuth = false`), when the user is authenticated (`isAuthenticated`).
                    if (!withAuth) {
                        if (isAuthenticated) {
                            this.appSvc.navigateToMainRoute();
                            return false;
                        }
                    }
                    // Restrict the access of the route, when the user is not authenticated or the `chkTokenExp` flag is
                    // active for the activated route and the token is expired (NOTE: `withAuth = true`).
                    else if (
                        !isAuthenticated
                        || (chkTokenExp && (tokenExpired = this.tokenSvc.payload.exp <= new Date().getTime()))
                    ) {

                        // Store the attempted URL for redirecting
                        this.authSvc.redirectUrl = url;

                        // When the JWT token is expired, logout the current user.
                        if (tokenExpired) {
                            this.authSvc.logout(true);
                        }
                        // The user is not authenticated, redirect to the `sign-in` route.
                        else {
                            this.router.navigate([this.localize.translateRoute('/sign-in')]);
                        }

                        return false;
                    }

                    // Restrict the access of the route, when the user is authenticated, but
                    // not authorized to access the activated route.
                    if (
                        isAuthenticated
                        && !this.isAuthorized(routeData, true)) {
                        this.appSvc.navigateToMainRoute();
                        return false;
                    }

                    return true;
                })
            );
    }

    /**
     * Checks the user authorization for the activated route, only when the user is authenticated.
     * @param {Data} routeData the route date object.
     * @returns {boolean} whether the route is authorized.
     */
    isAuthorized(routeData: Data, fromAuth = false) {
        if (fromAuth) {
            // console.log('route data', routeData);
        }
        // `tokenPayload` is undefined for un-authenticated users.
        const tokenPayload = this.tokenSvc.payload;

        // Restrict the access of the route, because the user is un-authorized
        // NOTE: this extra check is needed for the @see `CustomPreloadingStrategyService.preload()`.
        if (!tokenPayload) {
            return false;
        }
        // The route data object holds the route authorization restrictions, so
        // when there is no route data, then is authorized.
        if (routeData) {
            const module = this.settingsSvc.getModules().find(x => x.Id === routeData.mid);

            // Check the route can be accessed by the company modules.
            if (routeData.mid
                && !module) {
                return false;
            }

            // // Check the route has permission data
            // if (!routeData.perm) {
            //     return false;
            // }

            // Check the route can accessed by the user role.
            if (routeData.perm
                && this.settingsSvc.getUserPermissions().indexOf(routeData.perm) < 0) {
                return false;
            }
        }

        return true;
    }
}
