
export class EquipmentType {
    public Id: number;
    public Key: string;
    public Name: string;
    constructor(
        params?: {
            Id?: number,
            Key?: string,
            Name?: string,
        }
    ) {
        this.Id = params['Id'] || 0;
        this.Key = params['Key'] || '';
        this.Name = params['Name'] || '';
    }
}
