import * as moment from 'moment';
import { UtilService } from '../service/util.service';
import { CFG } from '../config';
export class ServiceRoute {
    public Id: number;
    public ImportanceLevel: number;
    public Description: string;
    public RepairDescription: string;
    public DueDate: string;
    public DateAdded: string;
    public Reporter: string;
    public DateCompleted: string;
    public FileUrl: any;
    public Photos: { Id: number, Name: string }[];
    public RepairPhotos: { Id: number, Name: string }[];
    public AssignedId: number;
    public Inspection: any;
    public ComponentPart: any;
    constructor(params: {
        Id?: number,
        ImportanceLevel?: number,
        Description?: string,
        RepairDescription?: string,
        DueDate?: string,
        DateAdded?: string,
        Reporter?: string,
        DateCompleted?: string,
        FileUrl?: string,
        Photos?: { Id: number, Name: string }[],
        RepairPhotos?: { Id: number, Name: string }[],
        AssignedId?: number,
        Inspection?: any
        ComponentPart?: any
    }
    ) {
        this.Id = params.Id || 0;
        this.ImportanceLevel = params.ImportanceLevel || 0;
        this.Description = params.Description || '';
        this.RepairDescription = params.RepairDescription || '';
        this.DueDate = params.DueDate || '';
        this.DateAdded = params.DateAdded ? moment(params.DateAdded).format('YYYY-MM-DD HH:mm') : '';
        this.Reporter = params.Reporter || '';
        this.DateCompleted = params.DateCompleted || '';
        this.FileUrl = params.FileUrl || {};
        this.Photos = params.Photos || [];
        this.RepairPhotos = params.RepairPhotos || [];
        this.AssignedId = params.AssignedId || null;
        this.Inspection = params.Inspection || {};
        this.ComponentPart = params.ComponentPart || {};
    }

    prepareForClient(timezone: string) {
        this.DateAdded = UtilService.getTimeFromUtc(this.DateAdded, timezone, CFG.serverTimeFormats.dateTime) as string;
    }
}
