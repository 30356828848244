import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-general',
  templateUrl: './dialog-general.component.html',
  styleUrls: ['./dialog-general.component.scss']
})
export class DialogGeneralComponent implements OnInit {
  @Input() title: string;
  @Input() content: string;
  @Input() actions: any[];
  @Input() btnCancel = true;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
