import { InjectionToken } from '@angular/core';

import { BaseDailyReportService } from './base-daily-report-service';
import { ApiService } from './api.service';

import { IncidentReportHandling } from '../model';
import { BaseFeatureModuleService } from '@app/core/service/base-feature-module-service';

export const IncidentReportHandlingService = new InjectionToken<BaseFeatureModuleService<IncidentReportHandling>>('IncidentReportHandling');

export function incidentReportHandlingSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<IncidentReportHandling> {
    return new BaseFeatureModuleService<IncidentReportHandling>(apiSvc, 'report/incident/handling');
}
