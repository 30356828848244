import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';

import { Observable ,  of } from 'rxjs';
import { AuthGuardService } from '../auth/auth-guard.service';

@Injectable()
export class CustomPreloadingStrategyService implements PreloadingStrategy {

    constructor(private authGuardService: AuthGuardService) { }

    /**
     * @inheritDoc
     */
    preload(route: Route, load: () => Observable<any>): Observable<any> {

        // Do not need to check the token expiration, because the app preload all of the authorized modules, when the
        // app loads, so only check authentication with the token payload existence.
        // Modules can be preloaded only, when the user is authenticated (@see `AuthGuardService.isAuthorized()`).
        return this.authGuardService.isAuthorized(route.data) && load() || of(null);
    }
}
