export class BeltCategory {
    public Id: number;
    public Name: string;
    public EquipmentTypeId: number;
    public Description: string;
    public DaysMaintenance;
    public UsageMaintenance;
    public FileUrl: string;
    public Manuals: File[];
    public PartTypes: any[];
    public InspectionTypeIds: number[];
    constructor(
        params: {
            Id?: number,
            EquipmentTypeId?: number,
            Name?: string,
            Description?: string,
            DaysMaintenance?: number,
            UsageMaintenance?: number,
            FileUrl?: string,
            Manuals?: File[],
            PartTypes?: any[],
            InspectionTypeIds?: number[]
        }
    ) {
        this.Id = params.Id || 0;
        this.EquipmentTypeId = params.EquipmentTypeId || 0;
        this.Name = params.Name || '';
        this.Description = params.Description || '';
        this.DaysMaintenance = params.DaysMaintenance || 0;
        this.UsageMaintenance = params.UsageMaintenance || 0;
        this.FileUrl = params.FileUrl || '';
        this.Manuals = params.Manuals || [];
        this.PartTypes = params.PartTypes || [];
        this.InspectionTypeIds = params.InspectionTypeIds || [];
     }
}
