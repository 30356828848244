import { filter } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Route } from '@app/core';

import { Subject } from 'rxjs';

import { CyNode } from '@app/core/model/cynode.model';
import { CyEdge } from '@app/core/model/cyedge.model';
import { CyElement } from './../model/cyelement.model';

import { v4 as uuid } from 'uuid';
import * as _ from 'lodash';
import { CyComponent } from '@app/core/model';
import { UtilService } from './util.service';
import { CanvasHelperService } from '../ng-cytoscape/canvas.helper';

@Injectable()
export class CytoscapeService {
    public clickedOut: EventEmitter<any> = new EventEmitter();
    public clickedNodeSubject = new Subject<string>();
    public clickedEdgeSubject = new Subject<string>();
    public rightClickSubject = new Subject<string>();
    public routeTypeChanged = new Subject<number>();
    public panedSubject = new Subject();
    public zoomSubject = new Subject();

    // Canvas extension
    public ctx: any;
    public bottomLayer: any;

    public selectedIdOfEleId: string;
    public structureHelper = {
        trees: 0,
        edges: 0,
        platforms: 0,
        ladders: 0,
        uniqueNames: [],
    };


    public cy: any;
    public fromNodeId: string;

    // RouteType variables
    public selectedRouteTypeId = 0;
    public routes: Route[];

    /* flags */
    private newEdgeFlag = false;

    // For inspection map mode
    public isInspectionMode = false;
    public inspectionElementId = '';

    constructor() {
        this.routeTypeChanged.subscribe((id: number) => {
            this.selectedRouteTypeId = id;
            this.updateCyStyle();
            // update style for changin edges colors
        });
    }

    public loadRoutes(json, ungrabify = false) {
        this.structureHelper = json.numbers || {
            trees: 0,
            edges: 0,
            platforms: 0,
            ladders: 0,
            uniqueNames: [],
        };

        this.cy.json({
            elements: json.elements,
            zoom: json.zoom,
            pan: json.pan
        });
        if (ungrabify) {
            this.cy.nodes().ungrabify();
        }
        this.cy.maxZoom(3);
        this.cy.minZoom(0.5);

        this.drawWarning([ this.inspectionElementId ]);
    }

    public zoom(mode: number) {
        if (mode === -1) {
            this.cy.zoom(this.cy.zoom() - 0.1);
        }
        if (mode === 1) {
            this.cy.zoom(this.cy.zoom() + 0.1);
        }
        // this.cy.center();
    }

    public addNewNodeToElements(x: number, y: number, type: number) {
        this.structureHelper.trees++;
        const name = `T${this.structureHelper.trees}`;
        this.structureHelper.uniqueNames['trees'].push(name);
        const node = new CyNode(name, name, type);
        node.setPosition(x, y);
        this.cy.add(node);
    }

    public addEdge(elementI: CyComponent, typeOfElement: string, source, target) {
        this.structureHelper.uniqueNames[this.selectedRouteTypeId].push(elementI.Name);
        this.structureHelper.edges++;
        // this.structureHelper.uniqueNames.push(edge.data.name);
        const color =  this.routes.find((type: Route) => {
            return type.Id === this.selectedRouteTypeId;
        }).Color;
        const edge = new CyEdge();
        edge.group = 'edges';
        edge.data.compId = elementI.Id;
        edge.data.source = source;
        edge.data.target = target;
        edge.data.routeId = this.selectedRouteTypeId;
        edge.data.type = typeOfElement;
        edge.data.color = color;
        edge.data.lineStyle = typeOfElement === 'HelperCable' ? 'dashed' : 'solid';
        this.cy.add(edge);
    }

    public addComponentToNode(elementI: CyComponent, typeOfElement: string) {
        this.structureHelper.uniqueNames[this.selectedRouteTypeId].push(elementI.Name);

        if (typeOfElement === 'Platform') {
            this.structureHelper.platforms++;
        } else {
            this.structureHelper.ladders++;
        }

        const elements = this.cy.$(':selected').data('elements');
        const color =  this.routes.find((type: Route) => {
            return type.Id === this.selectedRouteTypeId;
        }).Color;

        elements.push({
            Id: elementI.Id,
            RouteId: this.selectedRouteTypeId,
            Color: color
        });
        this.cy.$(':selected').data('elements', elements);
    }

    public deleteSelected() {
        this.cy.$(':selected').remove();
    }

    public deleteByCompId(id) {
        this.cy.remove(`edge[compId = "${id}"]`);
    }

    public deleteUniqueNameByName(name, type) {
        const index = this.structureHelper.uniqueNames[type].indexOf(name);
        if (index !== -1) {
            this.structureHelper.uniqueNames[type].splice(index, 1);
        }
    }

    public addUniqueName(name, type) {
        this.structureHelper.uniqueNames[type].push(name);
    }

    public getSelectedItemDatas() {
        return this.cy.$id(this.selectedIdOfEleId).data();
    }

    public getSelectAttributeByIdAndName(id, name: string) {
        return this.cy.$id(id).data(name);
    }

    public getAttributesById(id) {
        return this.cy.$id(id).data();
    }

    public getSelectedEle() {
        return this.cy.$id(this.selectedIdOfEleId);
    }

    public getSelectedEleAttributeByName(attrName: string) {
        return this.cy.$id(this.selectedIdOfEleId).data(attrName);
    }

    public getNumberOfRootsByNodeId(id) {
        return this.cy.$id(id).connectedEdges().length;
    }

    public setSelectedEleAttributeByName(attrName: string, value: any) {
        return this.cy.$id(this.selectedIdOfEleId).data(attrName, value);
    }

    public updateSelectedItemDatas(item: any) {
        // update unique names
        const selectedName = this.cy.$(':selected').data().name;
        this.structureHelper.uniqueNames.push(selectedName);

        // update data in cytoscape
        this.cy.$(':selected').data(item);
    }

    public getElementNameById(id) {
        let name = this.cy.$id(id).data('name');
        if (!name) {
            this.cy.nodes().forEach(item => {
                const result = _.find(item.data('elements'), { id: id });
                if (result) {
                    name = result.name;
                }
            });
        }
        return name;
    }

    public printCy() {
    }

    // return number of different element pieces
    public getStructureHelper(): any {
        return this.structureHelper;
    }

    // return unique names
    public getUniqueNames(): string[] {
        return this.structureHelper.uniqueNames;
    }

    public isActiveRouteById(id) {
        const tmpRouteType = this.routes.find((type: Route) => {
            return type.Id === id;
        });

        return tmpRouteType.Active;
    }


    /* function for styles */

    // get label color
    public getLabelColor(id ) {
        const parentNode = this.getNodeIdByElementId(this.inspectionElementId);
        if (this.inspectionElementId === id || parentNode === id) {
            return 'red';
        }

        return 'black';
    }

    public getEdgeLabel(id): string {
        const parentNode = this.getNodeIdByElementId(this.inspectionElementId);
        if (this.inspectionElementId === id || parentNode === id) {
            return '!';
        }
        return '';
    }

    // return color by type
    public getEdgeColor(id: number, elementId: string): string {
        const tmpRouteType = this.routes.find((type: Route) => {
            return type.Id === id;
        });

        if (elementId === this.inspectionElementId) {
            return '#f44336';
        }

        if (tmpRouteType) {
            return tmpRouteType.Color;
        }

        return '#607d8b';
    }

    public getEdgeWidth(compId: string) {
        if (compId === this.inspectionElementId) {
            return 4;
        }

        return 2;
    }

    public getEdgeLabelColor(id: number): string {
        const tmpRouteType = this.routes.find((type: Route) => {
            return +type.Id === +id;
        });

        if ( !tmpRouteType) {
            return 'white';
        }

        if ( UtilService.isLightColor(tmpRouteType.Color) ) {
            return 'black';
        } else {
            return 'white';
        }
    }

    public getEdgeOpacity(routeId): number {
        const tmpRouteType = this.routes.find((type: Route) => {
            return type.Id === routeId;
        });

        if (Number(this.selectedRouteTypeId) === Number(routeId)) {
            return 1;
        }

        if (this.isInspectionMode && !tmpRouteType.Active) {
            return 0.3;
        }

        if ( this.selectedRouteTypeId === 0 ) {
            return 1;
        }

        return 0.2;
    }

    public getZIndex(routeId) {
        const tmpRouteType = this.routes.find((type: Route) => {
            return type.Id === routeId;
        });

        if (Number(this.selectedRouteTypeId) === Number(routeId)) {
            return 10;
        }

        if ( this.selectedRouteTypeId === 0 ) {
            return 1;
        }

        return 1;
    }
    public getNodeBorderColor(id): string {
        if (id === this.inspectionElementId) {
            return '#f44336';
        }

        // If elementId is a platform or ladder, then display error on the node
        const elementParentId = this.getNodeIdByElementId(this.inspectionElementId);
        if (id === elementParentId) {
            return '#f44336';
        }

        return '#37474f';
    }

    public cyZoom() {
        return this.cy.zoom();
    }

    public cyPan() {
        return this.cy.pan();
    }

    public updateCyStyle() {
        this.cy.style().update();
    }

    public saveJson(background) {
        const tmpJson = this.cy.json();
        const savedJson = {
            uniqueUpdated: 1,
            numbers: this.structureHelper,
            elements: tmpJson.elements,
            pan: tmpJson.pan,
            zoom: tmpJson.zoom,
            background: background
        };
        return savedJson;
    }

    public getNodeIdByElementId(elementId) {
        let nodeId = null;
        this.cy.nodes().forEach(item => {
            const result = _.find(item.data('elements'), { Id: elementId });
            if (result) {
                nodeId = item.data('id');
            }
        });
        return nodeId;
    }

    public addNotificationToInspectionItem() {
    }

    public drawWarning(elementIds: string[]) {
        this.cy.on('render cyCanvas.resize', (evt) => {
            this.bottomLayer.resetTransform(this.ctx);
            this.bottomLayer.clear(this.ctx);
            this.bottomLayer.setTransform(this.ctx);
            const width = 25 - Math.round(3  * this.cy.zoom());
            const height = 23 - Math.round(3  * this.cy.zoom());

            elementIds.forEach(elementId => {
                const elementParentId = this.getNodeIdByElementId(elementId);
                const edge = _.find(this.cy.edges(), e => (e.data('compId') === elementId || e.data('compId') === elementParentId));
                if (edge) {
                    const posSource = edge.source().position();
                    const posTarget = edge.target().position();
                    const midX = Math.round(( posSource.x + posTarget.x ) / 2);
                    const midY = Math.round(( posSource.y + posTarget.y ) / 2);

                    CanvasHelperService.drawWarningTriangle(this.ctx, midX, midY, width, height);
                }

                const node = _.find(this.cy.nodes(), n => (n.data('id') === elementId || n.data('id') === elementParentId));
                if (node) {
                    const pos = node.position();
                    CanvasHelperService.drawWarningTriangle(this.ctx, pos.x + 10, pos.y - 10, width, height);
                }
            });
            this.ctx.restore();
          });
    }
}
