import { trigger, transition, animate, style } from '@angular/animations';

export const slideInOut = trigger('slideInOut', [
  transition(':enter', [
    style({ transform: 'translateX(+100%)' }),
    animate('1000ms ease-in', style({ transform: 'translateX(0%)' }))
  ]),
  transition(':leave', [
    animate('1000ms ease-in', style({ transform: 'translateX(+100%)' }))
  ])
]);
