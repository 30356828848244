export class ComponentType {
    Id: number;
    Name: string;
    IncludeDeprecation: number;
    DeprecationRequired: number;
    IncludePartCategories: number;
    IncludeParts: number;
    IncludeBelaySystemParts: number;
    IncludeLength: number;
    IsElement: number;
    Key: string;

    constructor(
        params: {
            Id?: number,
            Name?: string,
            IncludeDeprecation?: number,
            DeprecationRequired?: number,
            IncludePartCategories?: number,
            IncludeParts?: number,
            IncludeBelaySystemParts?: number,
            IncludeLength?: number,
            IsElement?: number,
            Key?: string,
        }
    ) {
        this.Id = params['Id'] || 0;
        this.Name = params['Name'] || '';
        this.IncludeDeprecation = params['IncludeDeprecation'] || 0;
        this.DeprecationRequired = params['DeprecationRequired'] || 0;
        this.IncludePartCategories = params['IncludePartCategories'] || 0;
        this.IncludeParts = params['IncludeParts'] || 0;
        this.IncludeBelaySystemParts = params['IncludeBelaySystemParts'] || 0;
        this.IncludeLength = params['IncludeLength'] || 0;
        this.IsElement = params['IsElement'] || 0;
        this.Key = params['Key'] || '';
    }
}
