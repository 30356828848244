import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { Route } from '../model';

export const RouteService = new InjectionToken<BaseFeatureModuleService<Route>>('Route');

export function routeSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<Route> {
    return new BaseFeatureModuleService<Route>(apiSvc, 'route', {
        getItems: { expand: 'Park,RouteInspection,DaysToInspections' },
        getItem: { expand: 'Settings,Manuals' },
    });
}
