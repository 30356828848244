import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';

import * as moment from 'moment';

@Injectable()
export class FieldValidationService {
    static isNotPast(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const date = control.value;
            if (!date) {
              return null;
            }
            const today = moment().format('YYYY-MM-DD');
            if (moment(date).isSameOrAfter(today)) {
              return null;
            } else {
              return { 'notPast': { value: date } };
            }
          };
    }

    isNotFuture(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const date = control.value;
            const today = moment();
            if (moment(date).isAfter(today)) {
                return { 'notFuture': { value: date } };
            } else {
                return null;
            }
        };
    }


    isBetweenTwoDate(first: any, second: any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const date = control.value;
            if (moment(date).isBetween(first, second, null, '[]')) {
                return null;
            } else {
                return { 'notFuture': { value: date } };
            }
        };
    }
}
