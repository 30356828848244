import { Inject, Injectable } from '@angular/core';

import { WINDOW } from '../window-factory';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable()
export class FormLocalDataService {
    private ls: any;


    constructor(@Inject(WINDOW) private window: Window,
                private router: Router
    ) {
        this.ls = this.window.localStorage;
    }

    saveFormData(formData: any): void {
        this.ls.parkupkeep_formData = JSON.stringify(formData);
    }

    redirectPage(): boolean {
        const localFormData = this.ls.parkupkeep_formData ? JSON.parse(this.ls.parkupkeep_formData) : undefined;

        if (!localFormData) {
            return false;
        }

        this.router.navigate([localFormData.url]);
        return true;
    }

    loadFormData(): any {
        const localFormData = this.ls.parkupkeep_formData ? JSON.parse(this.ls.parkupkeep_formData) : undefined;

        if (!localFormData) {
            return false;
        }

        this.ls.removeItem('parkupkeep_formData');

        return localFormData;
    }
}
