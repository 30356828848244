import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { EquipmentInterface } from './../model/belt/equipment.interface';

export const BeltService = new InjectionToken<BaseFeatureModuleService<EquipmentInterface>>('BeltService');

export function beltSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<EquipmentInterface> {
    return new BaseFeatureModuleService<EquipmentInterface>(apiSvc, 'belt',
    {
        getItems: {
            expand: 'Category,DaysToMaintain,UsagesCntToMaintain,Park,Status'
        },
        getItem: {
            // tslint:disable-next-line:max-line-length
            expand: 'Category,DaysToMaintain,UsagesCntToMaintain,DeprecationDate,DaysUsed,UsagesCnt,ModifiedByUser,Services,Usages,Parts,Manuals'
        }
    }
);
}
