import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';

import { FieldBase } from './field';

import { emailValidator, urlValidator } from './validator/validator-factory';
import { ArrayboxField } from '@app/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class FieldControlService {
    DropzoneSendedSubject: Subject<any> = new Subject();

    constructor(private _translate: TranslateService) {}

    toFormGroup(fields: FieldBase<any>[]): FormGroup {
        return this.createGroupFromFields(fields);
    }

    createArrayFormFromField(field): FormArray {
        const formArray: FormControl[] = [];
        field.fields.forEach(innerField => {
            formArray.push(this.createFormControlFromField(innerField));
        });

        return new FormArray(formArray);
    }

    createGroupFromFields(fields): FormGroup {
        const group: { [key: string]: FormControl | FormArray | FormGroup } = {};

        fields.forEach(field => {
            switch (field.controlType) {
                case 'dropzone': {
                    return;
                }
                case 'arraybox': {
                    group[field.key] = this.createArrayFormFromField(field);
                    break;
                }
                case 'formgroup': {
                    group[field.key] = this.createGroupFromFields(field.fields);
                    break;
                }
                default: {
                    group[field.key] = this.createFormControlFromField(field);
                    break;
                }
            }
        });

        return new FormGroup(group);
    }

    createFormControlFromField(field) {
        field.placeholder = field['placeholder'] || field.label && `${this._translate.instant('form.placeholderEnter')} ${field.label.toLowerCase()}` || '';
        if (field.required) {
            field.validators.push(Validators.required);
        }

        // Max length
        if (field.maxLength) {
            field.validators.push(Validators.maxLength(field.maxLength));
        }

        // Email
        if (field['type'] === 'email') {
            field.validators.push(emailValidator);
        }

        // URL
        if (field['type'] === 'url') {
            field.validators.push(urlValidator);
        }
        if (field['type'] !== 'checkbox') {
            field.value = field.value || null;
        }

        return new FormControl({ value: field.value, disabled: field.disable },  {validators: field.validators, updateOn: field.updateOn});
    }
}
