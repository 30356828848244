export const CFG = Object.freeze({
    locales: ['en-GB', 'nl', 'de'],
    defaultTitle: 'ParkUpKeep',
    toastDuration: 1.5 * 1000, // 1.5s in ms
    sideNavToggleDebounceTime: 0.4 * 1000, // 0.4s in ms
    dzOverlayDebounceTime: 0.15 * 1000, // 0.15s in ms
    regex: {
        // tslint:disable-next-line:max-line-length
        url: new RegExp('^(?:(?:https?|ftp):\\/\\/)?(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\\.(?:[a-z\u00a1-\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$', 'i')
    },
    serverTimeFormats: {
        date: 'YYYY-MM-DD',
        dateTime: 'YYYY-MM-DD HH:mm:ss',
        time: 'HH:mm:ss',
        shortTime: 'HH:mm'
    },
    error: {
        E000: 'Something went wrong...',
        E001: '{0} has already been loaded. Import Core modules in the AppModule only.',
        E002: '{0} is invalid.',
        E003: '{0} is required.',
        E004: '{0} cannot be more than {1} characters long.',
        E005: '{0} must be more than {1} characters long.',
        E006: 'Your session expired.',
        E007: 'Login details incorrect, please try again.',
        E008: 'The requested record doesn\'t exists.'
        // E002: 'Login details incorrect, please try again.',
        // E003: 'Your session expired.',
        //
        // E007: 'Cannot find this address.',
        // E008: 'The requested record doesn\'t exists.',
        // E009: 'You don\'t have permission to modify a main category.',
        // E010: '{0} is invalid ({0} must be between {1} and {2} degrees inclusive).'
    },
    msg: {
        M001: 'Are you sure?',
        M002: 'Are you sure you want to perform this action?',
        M003: 'Delete current area?',

        M005: 'Successfully saved!',
        M006: 'Successfully updated!',
        M007: 'Successfully deleted!',
        M008: 'Discard changes?',
        M009: '<p>Are you sure you want to leave this page?</p><p>Your changes will be lost!</p>',
        M010: 'You can add your files by dropping them into the form area.',
        M011: 'Drop it like it’s hot',
        M012: 'Add your files by dropping them anywhere in the form area',

        /* Messages for route editor */
        M013: 'Delete selected item',
        M014: 'Are you sure you want to delete the selected item?',

        M015: 'No Internet Access!',

        /* Messages for dropzone */
        M016: 'Click or drag images here to upload',
        M017: 'Click or drag manuals here to upload',
        M018: 'Click or drag image here to upload',
    },
    colors: ['#e53935', '#d81b60', '#8e24aa', '#5e35b1', '#3949ab', '#1e88e5', '#00897b', '#43a047', '#c0ca33', '#fb8c00', '#3e2723']
});
