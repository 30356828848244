import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-dialog-route-inspection-finalize',
  templateUrl: './dialog-route-inspection-finalize.component.html',
  styleUrls: ['./dialog-route-inspection-finalize.component.scss']
})
export class DialogRouteInspectionFinalizeComponent implements OnInit {
  @Input()
  courses: any[];

  @Input()
  inspectionTypes: any[];

  @Input()
  exists: any;

  collapses: any = {};

  warningText: string;
  updatedRecords = {};
  showWarning = false;
  constructor(public activeModal: NgbActiveModal,
        private translate: TranslateService) { }

  ngOnInit() {
    this.warningText = this.translate.instant('inspectorPark.warningNoData');
  }

  setCourseForInspectionType(event, typeId: number, courseId: number): void {
    const checked = event;
    // tslint:disable-next-line:curly
    if (!this.updatedRecords[typeId]) this.updatedRecords[typeId] = [];

    if (checked) {
      this.updatedRecords[typeId].push(courseId);
      this.showWarning = false;
      return;
    }

    const index = this.updatedRecords[typeId].findIndex(item => item === courseId);
    this.updatedRecords[typeId].splice(index, 1);

    if (this.updatedRecords[typeId].length === 0) {
      delete this.updatedRecords[typeId];
    }

    if (_.isEmpty(this.exists) && _.isEmpty(this.updatedRecords)) {
      this.showWarning = true;
    }
  }

  saveForm(): void {
    if (_.isEmpty(this.exists) && _.isEmpty(this.updatedRecords)) {
      this.showWarning = true;
      return;
    }
    this.activeModal.close(this.updatedRecords);
  }
}
