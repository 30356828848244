import { Component, Input } from '@angular/core';
import { Select2boxField } from '../field';
import { FormGroup } from '@angular/forms';
import { UtilService } from '@app/core/service';

@Component({
  selector: 'app-dynamic-select',
  templateUrl: './dynamic-select.component.html',
  styleUrls: ['./dynamic-select.component.scss']
})
export class DynamicSelectComponent {
  @Input() field: Select2boxField;
  @Input() form: FormGroup;
  constructor(public utilSvc: UtilService) { }
}
