import { FieldBase } from './field-base';

export class FormGroupField extends FieldBase<string> {
    controlType = 'formgroup';
    fields: FieldBase<any>[];

    constructor(options: {} = {}) {
        super(options);
        this.fields = options['fields'] || [];
        // this.placeholder = options['placeholder'] || this.label && ('Enter ' + this.label.toLowerCase()) || '';
    }

    addField(field: FieldBase<any>) {
        this.fields.push(field);
    }
}
