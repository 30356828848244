import { Address } from './address.model';
import { Person } from '@app/core';

export class Supplier {
    public Address: Address;
    public Id: number;
    public Name: string;
    public Phone: string;
    public Email: string;
    public Website: string;
    public Description: string;
    public SupplierContacts: Person[];
    constructor(
        params: {
            Id?: number,
            Name?: string,
            Phone?: string,
            Email?: string,
            Website?: string,
            Description?: string,
            SupplierContacts?: Person[],
            Address?: Address,
        } = {}
    ) {
        this.Id = params['Id'] || 0;
        this.Name = params['Name'] || '';
        this.Phone = params['Phone'] || '';
        this.Email = params['Email'] ? params['Email'].trim() : '';
        this.Website = params['Website'] ? params['Website'].trim() : '';
        this.Description = params['Description'] || '';
        this.SupplierContacts = params['SupplierContacts'] || [];
        this.Address = params['Address'] || new Address({});
    }

    setAddress( address: Address ) {
        this.Address = address;
    }
}
