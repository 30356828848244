import { FieldControlService } from './../dynamic-form/field-control.service';
export class DailyReport {
    public Id: number;
    public ParkId: number;
    public Date: string;
    public Weather: string;
    public GuestNr: number;
    public DayManagerUserId: number;
    public Data: any;
    public Employees: any[];

    constructor(report: {
        Id?: number,
        ParkId?: number
        Date?: string,
        Weather?: string,
        GuestNr?: number,
        DayManagerUserId?: number,
        Data?: any,
        Employees?: any[]
        }
    ) {
        this.Id = report.Id || 0;
        this.ParkId = +report.ParkId || 0;
        this.Date = report.Date || '';
        this.Weather = report.Weather || '';
        this.GuestNr = report.GuestNr || 0;
        this.DayManagerUserId = report.DayManagerUserId || null;
        this.Data = report.Data || {};
        this.Employees = report.Employees || [];
    }

    changeValuesToServer() {
    }
}
