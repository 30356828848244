import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';

@NgModule({
  imports: [
    CommonModule,
    GalleryModule.forRoot()
  ],
  declarations: [
    GalleryComponent
  ],
  exports: [
    GalleryComponent,
    GalleryModule
  ]
})
export class SharedGalleryModule { }
