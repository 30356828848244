import { ToastService } from './toast.service';
import { Injectable } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { CFG } from './../config/app-config.constants';

@Injectable()
export class HttpErrorHandlingService {
    constructor(private toastSvc: ToastService) {
    }

    error(err: HttpErrorResponse) {
        if (err) {
            switch (err.status) {
                case 400:
                this.toastSvc.error(err.error.data.message);
                    break;
                case 403:
                    this.toastSvc.error(err.error.data.message);
                    break;
                case 422:
                    this.toastSvc.errorFromArray(err.error.data);
                    break;
                case 404:
                    this.toastSvc.error(CFG.error.E008);
                    break;
                default:
                    this.toastSvc.error(CFG.error.E000);
                    break;
            }
        }
    }
}
