import { ValidatorFn } from '@angular/forms';

import { CFG } from '../../config';
import { UtilService } from '../../service';

export class FieldBase<T> {
  value: T;
  key: string;
  label: string;
  order: number;
  controlType: string;
  required: boolean;
  minLength: number;
  maxLength: number;
  validators: ValidatorFn[] = [];
  error: string;
  errorMessages: {} = {};
  disable: boolean;
  subtitle: string;
  hidden: boolean;
  translateKey: string;
  subtitleTranslateKey: string;
  labelAfter: boolean;
  styleClass: string;
  updateOn: string;
  message: string;

  constructor(
    options: {
      value?: T;
      key?: string,
      label?: string,
      order?: number,
      controlType?: string,
      required?: boolean,
      minLength?: number,
      maxLength?: number,
      validators?: ValidatorFn[],
      error?: string,
      errorMessages?: {},
      disable?: boolean,
      subtitle?: string,
      hidden?: boolean,
      translateKey?: string,
      subtitleTranslateKey?: string,
      labelAfter?: boolean,
      styleClass?: string,
      message?: string,
      updateOn?: string
    } = {}
  ) {
    // Init default values
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.order = (options.order === undefined && 1) || options.order;
    this.controlType = options.controlType || '';
    this.required = !!options.required;
    this.minLength = options.minLength || null;
    this.maxLength = options.maxLength || null;
    this.validators = options.validators || [];
    this.error = '';
    this.disable = options.disable || false;
    this.subtitle = options.subtitle || '';
    this.hidden = options.hidden || false;
    this.translateKey = options.translateKey || '';
    this.subtitleTranslateKey = options.subtitleTranslateKey || '';
    this.labelAfter = options.labelAfter || false;
    this.styleClass = options.styleClass || 'col-md-12';
    this.updateOn = options.updateOn || 'change';
    this.message = options.message || '';
    // Build error messages
    const fieldName =
      this.label || this.key.charAt(0).toUpperCase() + this.key.slice(1);

    this.errorMessages['default'] = UtilService.format(
      CFG.error.E002,
      fieldName
    );
    this.errorMessages['required'] = UtilService.format(
      CFG.error.E003,
      fieldName
    );

    if (this.minLength !== null) {
      this.errorMessages['minLength'] = UtilService.format(
        CFG.error.E005,
        fieldName,
        this.minLength
      );
    }

    if (this.maxLength !== null) {
      this.errorMessages['maxlength'] = UtilService.format(
        CFG.error.E004,
        fieldName,
        this.maxLength
      );
    }

    Object.assign(this.errorMessages, options.errorMessages || {});
  }
}
