import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';


import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable()
export class DynamicFormDzService {
    public queuedFileNumber = 0;

    DropzoneWasSendedSubject: Subject<{ path: string, formData: any }> = new Subject();
    DropzoneWasSendedWithFormDataSubject: Subject<{ path: string, formData: any }> = new Subject();
    DropzoneLoadFilesSubject: Subject<{ url: string, files: File[] }> = new Subject();
    DropzoneLoadFileSubject: Subject<{ url: string, file: string, id: number, type: string }> = new Subject();
    DropzoneDoneUploadSubject: Subject<{ success: boolean, files: number, data?: any }> = new Subject();
    DropzonePurgeFilesSubject: Subject<any> = new Subject();
    DropzoneBase64FilesSubject: Subject<any> = new Subject();
    DropzoneNumberOfImagesChangedSubject: Subject<number> = new Subject();
    lastUploadedFile: any;
    files: any[] = [];
    constructor() {
    }

    public async getFilesAsBase64() {
        const files = this.files;
        const tmpPromises = [];
        const filesBase64 = [];
        files.forEach(file => {
            tmpPromises.push(this.readUploadedFileAsText(file));
        });

        return await Promise.all(tmpPromises);
    }

    readUploadedFileAsText = (inputFile) => {
        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new Error('Problem parsing input file.'));
            };

            temporaryFileReader.onload = () => {
                resolve({ data: temporaryFileReader.result, type: inputFile.type, name: inputFile.name });
            };
            temporaryFileReader.readAsDataURL(inputFile);
        });
    }
}
