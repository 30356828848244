import { Injectable } from '@angular/core';

@Injectable()
export class CanvasHelperService {
  static drawWarningTriangle(context, x, y, width = 25, height = 23) {
    // context.globalCompositeOperation='darken';
    // Load the context of the canvas
    const padding = 0;
    const lineWidth = 1.5;
    const primaryColor = '#ffc821';
    const secondaryColor = '#faf100';
    const tertiaryColor = '#dcaa09';
    // Create a triangluar path
    context.beginPath();
    context.moveTo(x - width / 2, y + height / 2);
    context.lineTo(x, y - height / 2);
    context.lineTo(x + width / 2, y + height / 2);
    context.closePath();

    // Create fill gradient
    let gradient = context.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, primaryColor);
    gradient.addColorStop(1, secondaryColor);

    // Add a shadow around the object
    context.shadowBlur = 10;
    context.shadowColor = 'black';

    // Stroke the outer outline
    context.lineWidth = lineWidth * 2;
    context.lineJoin = 'round';
    context.strokeStyle = gradient;
    context.stroke();

    // Turn off the shadow, or all future fills will have shadows
    context.shadowColor = 'transparent';

    // Fill the path
    context.fillStyle = gradient;
    context.fill();

    // Add a horizon reflection with a gradient to transparent
    gradient = context.createLinearGradient(0, padding, 0, padding + height);
    gradient.addColorStop(0, 'transparent');
    gradient.addColorStop(0.5, 'transparent');
    gradient.addColorStop(0.5, tertiaryColor);
    gradient.addColorStop(1, secondaryColor);

    context.fillStyle = gradient;
    context.fill();

    // Stroke the inner outline
    context.lineWidth = lineWidth;
    context.lineJoin = 'round';
    context.strokeStyle = '#333';
    context.stroke();

    // Draw the text exclamation point
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.font = `bold ${height - 5}px 'Times New Roman', Times, serif`;
    context.fillStyle = '#333';
    try {
      context.fillText('!', x, y + height / 6);
    } catch (ex) {}
  }
}
