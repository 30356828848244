import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldBase } from '../field';

@Component({
  selector: 'app-dynamic-ui-switch',
  templateUrl: './dynamic-ui-switch.component.html',
  styleUrls: ['./dynamic-ui-switch.component.scss']
})
export class DynamicUiSwitchComponent implements OnInit {
  @Input() field: FieldBase<any>;
  @Input() form: FormGroup;
  constructor() { }

  ngOnInit() {
  }

}
