import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';

import { ApiService } from './api.service';

import { BeltPartType } from '../model';
import { BasesParkService } from '@app/core/service/base-park-service';

export const BeltPartTypeService = new InjectionToken<BaseFeatureModuleService<BeltPartType>>('BeltPartTypeService');

export function beltPartTypeSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<BeltPartType> {
    return new BaseFeatureModuleService<BeltPartType>(apiSvc, 'belt/part-type', {
        getItems: { expand: 'EquipmentType', 'equipment-type': '0' }
    });
}
