import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent implements OnInit {
  @Input() checked = false;
  @Input() disabled = false;
  @Output() changeChecked = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  onChange(checked) {
    this.changeChecked.emit(checked);
  }

}
