import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { RoutePartCategory } from '../model';

export const RoutePartCategoryService = new InjectionToken<BaseFeatureModuleService<RoutePartCategory>>('RoutePartCategory');

export function routePartCategorySvcFactory(apiSvc: ApiService): BaseFeatureModuleService<RoutePartCategory> {
    return new BaseFeatureModuleService<RoutePartCategory>(apiSvc, 'route/component-part-category', {
        getItems: { expand: 'Supplier,ComponentType' },
        getItem: { expand: 'Manuals' }
    });
}
