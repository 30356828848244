import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';

import { ApiService } from './api.service';

import { BasesParkService } from '@app/core/service/base-park-service';

export const ParkService = new InjectionToken<BasesParkService>('ParkService');

export function parkSvcFactory(apiSvc: ApiService): BasesParkService {
    return new BasesParkService(apiSvc, 'park', {
        getItems: { expand: 'RoutesCnt'},
        getItem: {expand: 'Routes,RouteComponents.ComponentType'}
    });
}
