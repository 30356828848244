import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { BeltCategory } from '../model';

export const BeltCategoryService = new InjectionToken<BaseFeatureModuleService<BeltCategory>>('BeltCategoryService');

export function beltCategorySvcFactory(apiSvc: ApiService): BaseFeatureModuleService<BeltCategory> {
    return new BaseFeatureModuleService<BeltCategory>(
        apiSvc, 'belt/category',
        {
            getItems: { expand: 'EquipmentType' },
            getItem: { expand: 'Manuals,PartTypes.Name,InspectionTypeIds' }
        }
    );
}
