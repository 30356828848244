import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { SharedModule } from './shared.module';
import { SharedDatatableComponent } from './datatable/shared-datatable.component';
import { RouterModule } from '@angular/router';
import { LocalizedDatePipe } from '@app/core/pipes/localized-date.pipe';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
    imports: [
        RouterModule,
        NgxDatatableModule,
        SharedModule,
        PipesModule,
        NgxSpinnerModule,
        UiSwitchModule
    ],
    declarations: [
        SharedDatatableComponent,
    ],
    exports: [
        RouterModule,
        NgxDatatableModule,
        SharedModule,
        SharedDatatableComponent
    ]
})
export class SharedDatatableModule {
}
