import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
@Component({
  selector: 'app-dialog-report-show',
  templateUrl: './dialog-report-show.component.html'
})
export class DialogReportShowComponent implements OnInit {
  @Input() reports: any = {};
  @Input() routes: any;
  @Input() inspectionTypes: any;
  @Input() nmReports: number;

  public reportsArray: any = [];
  public usageRoutes: any[] = [];
  public usageInspectionTypes: any[] = [];
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.buildUsageInspectionTypesArray();
  }

  private buildUsageInspectionTypesArray() {
    Object.keys(this.reports).forEach((inspTypeId) => {
      const inspectionType = this.inspectionTypes.find((item) => {
        return item.Id === Number(inspTypeId);
      });

      const tmoReportObject = {
        inspectionTypeId: inspTypeId,
        inspectionName: inspectionType.Name,
        routes: []
      };

      Object.keys(this.reports[inspTypeId]).forEach((routeId) => {
        const route = this.routes.find((item) => {
          return item.Id === Number(routeId);
        });

        tmoReportObject.routes.push({
          routeId: routeId,
          routeName: route.Name,
          reports: this.reports[inspTypeId][routeId]
        });
      });

      this.reportsArray.push(tmoReportObject);
    });
  }

  public removeReportFromReports(inspectionTypeId, routeId, index) {
    this.reports[inspectionTypeId][routeId].splice(index, 1);
    this.nmReports--;
  }

  saveReports() {
    // Close dialog and return new/edited item and parts
    this.activeModal.close(
      { reports: this.reports,
        nmReports: this.nmReports
      });
  }


}
